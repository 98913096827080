<template>
    <v-container fluid class="join-section" fill-height>
      <v-row class="py-12">
        <v-col cols="12"  class="d-flex align-center justify-center text-white text-center">
          <v-card max-width="600" class="pa-3">
            <v-card-title class="card-title">Who We Are</v-card-title>
            <v-card-subtitle class="card-subtitle">
                BAMBA was established in 2012 as the first <a class="content" href="https://www.imba.com/" target="_blank" rel="noopener noreferrer">IMBA (International Mountain Bicycling Association)</a>. Chapter in Berks County, PA. Today, BAMBA is a 501(c)3 non-profit trail association IMBA Affiliate, whose mission is to create, enhance, and preserve great trail experiences for mountain bikers in Berks County, Pennsylvania. 
            </v-card-subtitle>
          </v-card>          
        </v-col>
        <v-col cols="12"  class="d-flex align-center justify-center text-white text-center">
            <v-card max-width="600" class="pa-3">
                <v-card-title class="card-title">Our Values</v-card-title>
                <v-card-subtitle class="card-subtitle text-break">
                    Facilitate community involvement to thoughtfully plan for a future of outdoor recreation on the many multi-use trail systems in our area.<br><br>Be a key contributor of conservation, protection, and sustainable, professionally-built trails of Berks County's recreational preserves.<br><br>
                    Represent the interests of our members through maintaining strong, working relationships with sponsors, land managers, and partners.
                    <br><br>Encourage youth cycling to cultivate a lifelong passion for the sport with a focus on responsible, respectful riding and inclusion
                </v-card-subtitle>
            </v-card>
        </v-col>
        <v-col cols="12"  class="d-flex align-center justify-center text-white text-center">
            <v-card max-width="600" class="pa-3">
                <v-card-title class="card-title">Our Vision</v-card-title>
                <v-card-subtitle class="card-subtitle text-break">
                    Establish, preserve, and enhance access to mountain biking trails on local multi-use trail networks through education, communication, partnerships, and unified action.
                    <br><br>Create and enhance strong partnerships with local land managers, organizations, other trail user groups, businesses and government entities who support multi-use, sustainable trail systems.
                    <br><br>Encourage responsible, respectful riding and trail use.
                    <br><br>Support inclusion, wellness, fitness, healthy lifestyles, and friendships for all ages, genders, and abilities through mountain biking.
                    <br><br>Educate our community about the many benefits of mountain biking.
                </v-card-subtitle>
            </v-card>
        </v-col>
      </v-row>
    </v-container>
  </template>
  
  <script>
  export default {
    name: "AboutWe",
    methods:{
        openUrl(url){
            window.open(url, "_blank");
        }
    }
  };
  </script>
  
  <style scoped>
  .join-section {
    position: relative;
    background-image: url('@/assets/images/who-we-are/about-we-bg.jpg');
    background-position: center;
    background-size: cover;
    padding: 0 10%;
    color: #fff;
    overflow: hidden;
  }  
  
  .card-title {
    font-size: 1.5rem !important;
    justify-content: center;
    padding-bottom: 32px !important;
  }
  
  .card-subtitle{
    font-size: 100.1%;
  }
  .content {
    position: relative;
    z-index: 2;
    color:rgb(119, 119, 119, 1);
  }
  </style>
  