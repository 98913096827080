<template>
  <v-container class="community-section" fluid>
    <v-row justify="center" no-gutters>
      <v-col cols="12">
        <div class="community-wrapper">
          <p class="quote-text">
            "Whenever you ride, however you ride, wherever you ride, and whatever
            you ride with, we invite you to ride with us. Mountain bikers are not
            defined by frequency, skill, speed, or equipment. Passion for
            knobby-tire rides makes you a mountain biker!"
            <br />
            <span class="quote-author">-Katherine Fuller, Bikepacking Roots</span>
          </p>
          <h2 class="community-title">BAMBA IS COMMUNITY</h2>
          <p class="community-description">
            Whether you're a beginner or advanced mountain biker, we want everyone
            to feel welcome. Our rides, trail dig days, volunteer opportunities,
            and events give both men and women the opportunity to be a part of
            something bigger; the incredible Berks cycling community. Join us
            today!
          </p>

          <div class="button-group">
            <v-btn class="community-button" outlined large @click="$router.push('/events')">EVENTS/RIDES</v-btn>
            <v-btn class="community-button" outlined large @click="$router.push('/membership')">MEMBERSHIP</v-btn>
            <v-btn class="community-button" outlined large @click="$router.push('/volunteer')">VOLUNTEER</v-btn>
            <v-btn class="community-button" outlined large @click="$router.push('/committee-participation')">COMMITTEES</v-btn>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "CommunitySection",
};
</script>

<style scoped>
.community-section {
  background-color: #333;
  color: #fff;
  padding: 40px 20px;
}

.community-wrapper {
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
}

.quote-text {
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 1.6;
  margin-bottom: 20px;
}

.quote-author {
  font-weight: bold;
}

.community-title {
  font-size: 1.8rem;
  font-weight: bold;
  margin: 20px 0;
}

.community-description {
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 30px;
}

.button-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 12px;
}

.community-button {
  color: #fff;
  background-color: #444;
  border-color: #444;
  padding: 8px 16px;
  font-weight: 500;
  text-transform: uppercase;
}
</style>
