<template>
    <v-col cols="12" md="5" class="mb-4">
      <v-card class="committee-card">
        <v-card-title class="title-text text-break">{{ title }}</v-card-title>
        <v-card-text>
          <slot></slot>
        </v-card-text>
      </v-card>
    </v-col>
  </template>
  
  <script>
  export default {
    props: ['title']
  }
  </script>
  
  <style scoped>
  .committee-card {
    background-color: #242424 !important;;
    color: #FFFFFF;
    padding: 1rem;
  }
  
  .title-text {
    font-weight: bold;
    font-size: 1.5rem;
    text-align: center;
  }
  
  .lead-text, .members-text {    
    font-size: 1rem;
    color: #FFFFFF;
  }
  
  </style>
  