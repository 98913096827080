<template>
    <div>
      <v-row>
        <v-col cols="12" class="pb-0">
          <section-one />
        </v-col>
        <v-col cols="12" class="pt-0 pb-0" >
            <section-two />
        </v-col>
        <v-col cols="12" class="pt-0 pb-0">
          <section-three />
        </v-col>
        <v-col cols="12" class="pt-0 pb-0">
            <section-four />
        </v-col>
      </v-row>
        
    </div>
  </template>
  <script>
    import SectionOne from '../sponsorship/section-1';
    import SectionTwo from '../sponsorship/section-2';
    import SectionThree from '../sponsorship/section-3';
    import SectionFour from '../sponsorship/section-4';
  
    export default{
        components: {
            SectionOne,
            SectionTwo,
            SectionThree,
            SectionFour
        },
    }
  </script>