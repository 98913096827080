<template>
    <div>
      <v-row>
        <v-col cols="12" class="pb-0">
          <about-we />
        </v-col>
        <v-col cols="12" class="pt-0 pb-0" >
            <bord-directors />
        </v-col>
        <v-col cols="12" class="pt-0 pb-0">
          <contact-form />
        </v-col>
      </v-row>
        
    </div>
  </template>
  <script>
    import AboutWe from '../who-we-are/about-we';
    import BordDirectors from '../who-we-are/bord-of-directors';
    import ContactForm from '../who-we-are/contact-us';
  
    export default{
        components: {
            AboutWe,
            BordDirectors,
            ContactForm
        },
    }
  </script>