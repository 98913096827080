<template>
    <div>
      <events-page />
    </div>
  </template>
  
  <script>
   import EventsPage from '../../components/pages/events-page';
  
    export default {
      name: 'events-view',
      metaInfo: {
        title: 'Events - Berks Area Mountain Biking Association',
      },
      components: {
        EventsPage
      },
    }
  </script>
  