<template>
    <div>
      <get-involved />
    </div>
  </template>
  
  <script>
   import GetInvolved from '../../components/pages/get-involved-page';
  
    export default {
      name: 'get-involved-view',
      metaInfo: {
        title: 'Get Involved - Berks Area Mountain Biking Association',
      },
      components: {
        GetInvolved
      },
    }
  </script>
  