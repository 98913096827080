<template>
    <div>
      <sponsorship-page />
    </div>
  </template>
  
  <script>
   import SponsorshipPage from '../../components/pages/sponsorship-page';
  
    export default {
      name: 'sponsorship-view',
      metaInfo: {
        title: 'Sponsorship - Berks Area Mountain Biking Association',
      },
      components: {
        SponsorshipPage
      },
    }
  </script>
  