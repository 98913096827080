<template>
    <div>
      <v-row>
        <v-col cols="12" class="pb-0">
          <committee-participation />
        </v-col>
        <v-col cols="12" class="pt-0 pb-0" >
            <roles-responsibility />
        </v-col>
        <v-col cols="12" class="pt-0 pb-0">
          <contact-form />
        </v-col>
      </v-row>
        
    </div>
  </template>
  <script>
    import CommitteeParticipation from '../committee-participation/committee-participation'
    import RolesResponsibility from '../committee-participation/roles-responsibility';
    import ContactForm from '../committee-participation/contact-form';
  
    export default{
        components: {
            CommitteeParticipation,
            RolesResponsibility,
            ContactForm
        },
    }
  </script>