<template>
    <div>
      <news-page />
    </div>
  </template>
  
  <script>
   import NewsPage from '../../components/pages/news-comp';
  
    export default {
      name: 'news-view',
      metaInfo: {
        title: 'News - Berks Area Mountain Biking Association',
      },
      components: {
        NewsPage
      },
    }
  </script>
  