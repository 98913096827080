<template>
  <v-container class="py-10 bod_section" fluid>
    <v-row justify="center">
      <v-col cols="12" md="7">
        <v-row justify="center">
          <v-col cols="12" class="text-center">
            <h2 class="font-weight-bold">Board of Directors</h2>
          </v-col>

          <v-col v-for="(member, index) in boardMembers" :key="index" cols="12" sm="4" class="d-flex justify-center">
            <v-card elevation="2" class="pa-3" outlined max-width="250" min-width="250">
              <v-img :src="require(`@/assets/images/bod/${member.image}`)" alt="Board Member" class="bod_image"
                contain></v-img>
              <v-card-title class="text_css font-weight-bold justify-center">
                {{ member.name }}
              </v-card-title>
              <v-card-subtitle class="text_css">
                {{ member.position }}
              </v-card-subtitle>
            </v-card>
          </v-col>
        </v-row>
      </v-col>

    </v-row>
  </v-container>
</template>

<script>
  export default {
    data() {
      return {
        boardMembers: [
          { name: "Christa Bender", position: "Executive Director/ Events", image: "bod7_1.jpg" },
          { name: "Todd Papich", position: "Treasurer/Merchandise", image: "bod4_1.jpg" },
          { name: "Jeff Harrison", position: "Trail Maintenance and Developement", image: "bod6_1.jpg" },
          { name: "Jude Matteo", position: "Technology/Marketing", image: "bod8.jpg" },
          { name: "Bob McClennan", position: "Membership", image: "bod2.jpg" },
          { name: "Mark Kelly", position: "Strategic Funding", image: "bod9.jpg" },
          // { name: "Jennifer Motze", position: "Secretary, Marketing & Technology", image: "bod3_1.jpg" },
          // { name: "John Pacharis", position: "Trail Development, Events & Rides", image: "bod1.jpg" },
          // { name: "Todd Good", position: "Treasurer & Trail Development", image: "bod5.jpg" },

        ],
      };
    },
  };
</script>

<style scoped>
  .bod_section {
    background-color: #333333;
    color: #fff !important;
  }

  .v-card {
    background-color: #444444;
    /* Adjust for dark theme */
    color: #fff !important;
  }

  .text_css {
    color: #fff !important;
    text-align: center !important;
  }

  .bod_image {
    min-height: 200px;
    max-height: 200px;
    min-width: 200px;
    max-width: 200px;
  }
</style>