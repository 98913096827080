<template>
  <v-container fluid class="pa-8 grey darken-3 d-flex justify-center">
    <v-row class="w-100" style="max-width: 1200px;">
      <!-- Left Section: Instructions -->
      <v-col cols="12" md="6" class="text-center white--text">
        <h1 class="font-weight-bold">How to Get Involved</h1>
        <h2 class="font-weight-bold mb-8">It's easy!</h2>
        <p class="text-left mb-4">
          1. Sign up on the volunteer contact list so we can notify you of upcoming opportunities.
        </p>
        <p class="text-left mb-4">
          2. Stay in the loop on our <a href="/events" class="white--text text-decoration-underline">Events Page</a> and our social media outlets and let us know where you would like to help.
        </p>
        <p class="text-left mb-4">
          3. Interested in an open volunteer position below? <a href="mailto:info@berksmountainbiking.org?subject=committee interest" class="white--text text-decoration-underline">Email us here.</a>
        </p>
      </v-col>

      <!-- Right Section: Card with Buttons -->
      <v-col cols="12" md="6">
        <v-card class="pa-6 grey darken-2 text-center">
          <h4 class="white--text mb-2 font-weight-bold">Trail Workday Registration Form</h4>
          <v-btn class="grey lighten-3 black--text btn-block font-weight-bold" outlined @click="openForm('https://forms.gle/nmqbkq485ooGicPR6')">Sign Up Here</v-btn>
          
          <h4 class="white--text mt-8 mb-2">Complete this form prior to your first event</h4>
          <v-btn class="my-2 grey lighten-3 black--text btn-block font-weight-bold" outlined @click="openForm('https://forms.gle/2Hn45KctHhQEnGTY6')">Volunteer Waiver</v-btn>
          
          <h4 class="white--text mt-8 mb-2">Record your hours after the event</h4>
          <v-btn class="my-2 grey lighten-3 black--text btn-block font-weight-bold" outlined @click="openForm('https://forms.office.com/r/HpAiMAuDpM')">Volunteer Hours Log</v-btn>
          
          <h4 class="white--text mt-8 mb-2">Add yourself to the volunteer contact list</h4>
          <v-btn class="my-2 grey lighten-3 black--text btn-block font-weight-bold" outlined @click="openForm('https://lp.constantcontactpages.com/su/UMqkjVn/signup')">Volunteer Sign Up</v-btn>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "get-involved",
  methods: {
    openForm(url){
      window.open(url, "_blank");
    }
  }
};
</script>

<style scoped>
.text-center h2, .text-center h4 {
  font-weight: bold;
}
.text-left p {
  font-size: 16px;
  line-height: 1.6;
}
.btn-block {
  width: 100%;
  max-width: 250px;
  font-size: 14px;
}
</style>
