<template>
    <v-dialog v-model="dialog" persistent max-width="400">
      <v-card>
        <v-card-title class="headline">{{ title }}</v-card-title>
        <v-card-text>{{ message }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red"  @click="confirm(false)">NO</v-btn>
          <v-btn color="green"  @click="confirm(true)">YES</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </template>
  
  <script>
  export default {
    props: {
      title: {
        type: String,
        default: 'Confirmation',
      },
      message: {
        type: String,
        default: 'Are you sure you want to proceed?',
      },
    },
    data() {
      return {
        dialog: false,
      };
    },
    methods: {
      open() {
        this.dialog = true;
      },
      confirm(result) {
        this.dialog = false;
        this.$emit('confirm', result);
      },
    },
  };
  </script>
  
  <style scoped>
  .v-card-title {
    color: #000;
    font-weight: bold;
  }
  .v-btn {
    font-weight: bold;
  }
  </style>
  