<template>
    <v-container class="contact-container" fluid>
      <v-row justify="center" class="py-12">
        <v-col cols="12" md="8">
          <h2 class="contact-title">Contact Us</h2>
          <p class="contact-subtitle">
            We always welcome feedback!  Please reach out to us.
          </p>
          <v-form ref="form" v-model="valid">
            <v-row>
                <v-col cols="12" md="6">
                    <v-row>
                        <v-col cols="12" class="pb-0">
                            <v-text-field
                                v-model="firstName"
                                label="First name"
                                required
                                :rules="requiredFieldRules"
                                outlined dense class="custom-text-field">
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" class="pt-0 pb-0">
                            <v-text-field
                                v-model="lastName"
                                label="Last name"
                                required
                                :rules="requiredFieldRules"
                                outlined dense class="custom-text-field">
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" class="pt-0 pb-0">
                            <v-text-field
                                v-model="email"
                                label="Your email"
                                required
                                :rules="emailRules"
                                outlined dense class="custom-text-field">
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" class="pt-0 pb-0">
                            <v-text-field
                                v-model="subject"
                                label="Subject"
                                required
                                :rules="requiredFieldRules"
                                outlined dense class="custom-text-field">
                            </v-text-field>
                        </v-col>                        
                    </v-row>
                </v-col>            
                <v-col cols="12" md="6">
                <v-textarea
                    v-model="message"
                    label="Your message"
                    required
                    :rules="requiredFieldRules"
                    outlined dense class="custom-text-field" rows="8">
                </v-textarea>
                </v-col>
            </v-row>
    
            <v-row>
                <v-col cols="12" class="pt-0">
                <v-checkbox
                    v-model="consent"
                    label="By checking this box you confirm that it's ok for us to contact you. Thanks!"
                    class="custom-checkbox"
                ></v-checkbox>
                </v-col>
            </v-row>
          </v-form>
          <v-row>
            <v-col cols="12">
                <vue-hcaptcha
                    ref="hcaptcha"
                    sitekey="26fad0e6-1068-4bee-9193-3e095e48dd5f"
                    @verify="onCaptchaVerified"
                    @expired="onCaptchaExpired"
                />
            </v-col>
          </v-row>
            <v-snackbar v-model="snackbar" :timeout="timeout" class="snackbar-modal" :color="snackbarColor">
                    <div class="d-flex justify-space-between align-center">
                    {{ snackbarMessage }}
                    <v-btn color="white" class="ml-2" text @click="snackbar = false">Close</v-btn>
                    </div>
            </v-snackbar>
          <v-row justify="center" class="mb-5 mt-3">
            <v-btn @click="callPreview" large class="grey lighten-3 black--text btn-block font-weight-bold" outlined>Send Message</v-btn>
          </v-row>
        </v-col>
      </v-row>
      <v-dialog v-model="previewDialog" max-width="600" persistent>
        <v-card>
            <loader-comp :loading="loading" />
            <div class="d-flex justify-end close-btn-container">
                <v-btn icon @click="previewDialog = false" >
                    <v-icon large class="red--text">mdi-close</v-icon>
                </v-btn>
            </div>
            <v-card-title>
                <span class="headline">Send this message?</span>                
            </v-card-title>
            <v-card-text>You are about to send a message with the following information:</v-card-text>
            <v-card-text>
                <v-row>
                    <v-col cols="12">
                        <v-text-field
                            v-model="firstName"
                            label="First name"
                            readonly
                            outlined dense>
                        </v-text-field>
                        <v-text-field
                            v-model="lastName"
                            label="Last name"
                            readonly
                            outlined dense>
                        </v-text-field>
                        <v-text-field
                            v-model="email"
                            label="Your email"
                            readonly
                            outlined dense>
                        </v-text-field>
                        <v-text-field
                            v-model="subject"
                            label="Subject"
                            readonly
                            outlined dense>
                        </v-text-field>
                        <v-textarea
                            v-model="message"
                            label="Your message"
                            readonly
                            outlined dense rows="3">
                        </v-textarea>                        
                    </v-col>
                </v-row>                
            </v-card-text>
            <v-card-actions class="justify-center pb-6">
                <v-btn color="secondary" class="btn_min_100" @click="previewDialog = false">Back</v-btn>
                <v-btn color="primary" class="white--text btn_min_100" @click="submitForm">Continue</v-btn>
            </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </template>
  
  <script>
    import VueHcaptcha from '@hcaptcha/vue-hcaptcha';
    import axiosInstance from '@/utils/axiosConfig';
    import LoaderComp from '../shared/loader-comp';
  export default {
    name: "ContactForm",
    data(){
        return{
            loading: false,
            previewDialog: false,
            firstName: '',
            lastName: '',
            email: '',
            subject: '',
            message:'',
            consent: false,
            valid: false,
            requiredFieldRules: [v => (v && !!v.trim()) || 'This field is required'],
            emailRules: [
                v => (v && !!v.trim()) || 'Email is required',
                v => (v && /.+@.+\..+/.test(v.trim())) || 'Email must be valid',
            ],
            captchaToken: null,
            snackbar: false,
            snackbarColor: '',
            snackbarMessage: '',
            timeout: 6000,
            subscribedMsg: 'Your message has been sent successfully. Thank you for your interest and we will be in touch soon!',
        }
    },
    components:{
        VueHcaptcha,
        LoaderComp,
    },
    methods: {
        onCaptchaVerified(token) {
            this.captchaToken = token;
        },
        onCaptchaExpired() {
            this.captchaToken = null;
        },
        callPreview(){
            if (this.$refs.form.validate() && this.captchaToken){
                this.previewDialog = true;                
            }
        },
        async submitForm(){            
            try{
              this.loading = true;
                const contactData = {
                    firstName: this.firstName.trim(),
                    lastName: this.lastName.trim(),
                    email: this.email.trim(),
                    subject: this.subject.trim(),
                    message: this.message.trim(),
                    consent: this.consent,
                    source: 4,
                    token: this.captchaToken
                };
                await axiosInstance.post('contact.php', contactData);
                this.snackbarMessage = this.subscribedMsg;
                this.snackbarColor = 'success';
                this.snackbar = true;
                this.resetForm();                    
                this.loading = false;
                this.previewDialog = false;
            }catch (error) {
                this.snackbarMessage = error.response.data.message;
                this.snackbarColor = 'error';
                this.snackbar = true;
                this.loading = false;
                this.previewDialog = false;
            }
        },
        resetForm(){
            this.$refs.hcaptcha.reset();
            this.captchaToken = null;
            this.firstName = '',
            this.lastName = '',
            this.email = '';
            this.subject = '',
            this.message = ''
            if(this.$refs.form)
                this.$refs.form.reset();
        }
    }
  };
  </script>
  
  <style scoped>
  .contact-container {
    position: relative;
    background-image: url('@/assets/images/who-we-are/contact-us-bg.jpg');
    background-position: center;
    background-size: cover;
    padding: 0 10%;
    color: #fff;
    overflow: hidden;
  }
  
  .contact-title {
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
    font-size: 1.75rem;
    color: #ffffff;
    text-align: center;
    margin-bottom: 10px;
  }
  
  .contact-subtitle {
    font-size: 1rem;
    color: #fff;
    text-align: center;
    margin-bottom: 30px;
  }
  
  .submit-button {
    font-family: 'Roboto', sans-serif;
    font-size: 1rem;
    color: #333333;
    background-color: #ffffff;
    padding: 10px 20px;
    text-transform: none;
  }
  .close-btn-container {
    position: sticky;
    top: 10px;
    right: 10px;
    z-index: 1000;
  }
  .snackbar-modal {
    position: absolute;
    bottom: 16px;
    right: 16px;
  }
  </style>
  <style>
    .custom-text-field .v-input__control .v-input__slot {
        background-color: rgba(255, 255, 255, 0.5) !important;
        color: #fff !important;
    }

.custom-text-field .v-label {
  color: #fff !important;
}

.custom-text-field .v-input__control .v-input__slot .v-field__outline {
  border-color: white !important;       /* Set border color */
}
  
  .custom-checkbox .v-label {
    color: #fff;
  }

  .custom-checkbox .v-selection-control__ripple {
    display: none !important;
  }
    /* Set the icon color for the checked state */
  .custom-checkbox .v-icon {
    color: white !important; /* White color for checkmark */
  }

    /* Ensure inner black border is removed */
  .custom-checkbox .v-input--selection-controls__input .v-icon {
    border-color: transparent !important; /* Hide any inner black outline */
  }
  </style>
  