<template>
    <div>
      <get-involved />
    </div>
  </template>
  <script>
    import GetInvolved from '../get-involved/get-involved';
  
    export default{
        components: {
            GetInvolved
        },
    }
  </script>