<template>
    <div>
      <who-we-are />
    </div>
  </template>
  
  <script>
   import WhoWeAre from '../../components/pages/who-we-are-comp';
  
    export default {
      name: 'who-we-are-view',
      metaInfo: {
        title: 'Who We Are - Berks Area Mountain Biking Association',
      },
      components: {
        WhoWeAre
      },
    }
  </script>
  