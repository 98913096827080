<template>
    <div>
      <v-row>
        <v-col cols="12" class="pb-0">
          <join-member />
        </v-col>
      </v-row>
        
    </div>
  </template>
  <script>
    import JoinMember from '../membership/join-member';
  
    export default{
        components: {
            JoinMember
        },
    }
  </script>