<template>
    <v-container fluid class="main-section">
        <v-row class="justify-center text-left my-10">
            <v-col cols="12" md="9" lg="7" class="text-container text-white px-5">
                <h1 class="font-weight-bold mb-5">Become a Supporter or Sponsor Today!</h1>
                <p class="content">
                    Your support of BAMBA helps us achieve our vision: to create, enhance, and preserve great trail experiences for mountain bikers in Berks County, PA.  
                    You can help in two different ways; as a <span class=highlighter>Supporter</span> and a <span class=highlighter>Sponsor</span>.  You can choose one or both!  
                </p>
                <p class="content">
                    <span class=highlighter>SUPPORTERS</span> are local businesses who offer BAMBA Members a special promotion or discount. 
                    These promotions increase the value of a BAMBA membership and increase your business visibility to a quickly growing base of outdoor enthusiasts. While donations are always welcomed, there is not a donation expectation to become a Supporter.
                </p>
                <p class="content">
                    <span class=highlighter>SPONSORS</span> are businesses that support BAMBA through an annual donation.  It is best for businesses who want to increase their visibility to the cycling community, care about trail advocacy, strongly support the mountain biking community and local riders, and want to offer a higher level of support.
                </p>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    export default {
      name: "section-1",
    };
</script>

<style scoped>
    .main-section{
        background-color: #3F3F3F;
    }
    .text-white{
        color: #fff;
    }
    .content{
        font-size: 1.2rem;
    }
    .highlighter{
        font-size: 1.3rem;
        font-weight: 600;
    }
</style>