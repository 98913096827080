import { render, staticRenderFns } from "./bamba-community.vue?vue&type=template&id=2881497a&scoped=true"
import script from "./bamba-community.vue?vue&type=script&lang=js"
export * from "./bamba-community.vue?vue&type=script&lang=js"
import style0 from "./bamba-community.vue?vue&type=style&index=0&id=2881497a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2881497a",
  null
  
)

export default component.exports