<template>
    <v-container class="history-section" fluid>
      <v-row align="center" justify="center">
        <v-col cols="12" md="5" >
            <v-img
                src="@/assets/images/committee-participation/c-participation.jpg"
                alt="Get Return"
                max-width="600%"
                max-height="600px"
                contain
            />
        </v-col>
        <v-col cols="12" md="5">
          <v-card class="history-card" elevation="3">
            <v-card-title class="headline font-weight-bold card-title justify-center text-break">Committee Participation</v-card-title>
            <v-card-text class="content-1 mt-3 text-break">
              Do you have an initiative or project idea? <br>Are you passionate about mountain biking and want to give back to the mountain bike community?<br>Do you like marketing, building community, sales, finance, advocacy or technology?&nbsp; Become a committee member today!
            </v-card-text>
            <v-card-text class="content-1 text-break">
              All of our board members start by volunteering on committees, and we currently have several openings. One of the great benefits about being a BAMBA member is the ability to join committees and contribute your skills.
              <br> 
              <a class="link" href="mailto:info@berksmountainbiking.org?subject=committee participation"><b>Click here, reach out to us today</b></a> and let us know what you would like to do!
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </template>
  
  <script>
  export default {
    name: "CommitteeParticiaption",
  };
  </script>
  
  <style scoped>
  .history-section {
    background-color: #3F3F3F;
    padding: 40px 20px;
  }
  
  .history-card {
    background-color: #505050;
    padding: 20px 10px;
  }
  
  .content{
    color: #fff !important;
    font-size: 90.1%;
    text-align: center;
  }
  .content-1{
    color: #fff !important;
    font-size: 101.1%;
    text-align: center;
  }
  .card-title{
    color: #fff !important;
    font-size: 2.1rem !important;
    text-align: center !important;
  }
  .history-card .v-btn {
    margin-top: 15px;
  }
  .community-button {
    color: #fff;
    margin: 10px;
    background-color: rgb(119,119,119,1) !important;
    border-color: #444;
    padding: 8px 16px;
    font-weight: 500;
    text-transform: uppercase;
  }
 .link {
    position: relative;
    z-index: 2; 
    color: #FFFFFF; 
  }
  </style>
  