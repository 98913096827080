<template>
    <div>
      <current-project />
    </div>
  </template>
  
  <script>
   import CurrentProject from '../../components/pages/current-project-page';
  
    export default {
      name: 'current-project-view',
      metaInfo: {
        title: 'Current Projects - Berks Area Mountain Biking Association',
      },
      components: {
        CurrentProject
      },
    }
  </script>
  