<template>
    <v-container class="history-section" :class="{'full-height': $vuetify.breakpoint.mdAndUp}"  fluid>
      <v-row justify="center">
            <v-col cols="12" md="5" class="image-section">
                <v-img
                    src="@/assets/images/get-involved/get-involved.jpg"
                    alt="Get Involved"
                    height="auto"
                    contain
                />
            </v-col>
            <v-col cols="12" md="5">
                <v-card class="history-card" elevation="3">
                    <v-card-title class="headline font-weight-bold card-title justify-center text-break">More ways to get involved</v-card-title>
                    <v-card-text class="content-1 mt-3 text-break">
                        Our community is made up of many different people who all contribute in various ways.  Without the dedication of our volunteers, we would not have the trails and events that we all enjoy.
                    </v-card-text>
                    <v-card-text class="content-1 text-break">
                        Join us for a trail work day, jump in on a group ride, become a ride leader or trail crew leader, help out with event planning or taking photos, or help with administrative tasks and social media.
                    </v-card-text>
                    <v-card-text class="content-1 text-break">
                        If you would like to contribute, please let us know what you would like to do by emailing us below or check out the opportunities on our
                    <a class="link" href="/volunteer" target="_blank"><b>Volunteer page.</b></a>
                    </v-card-text>
                    <v-card-actions class="justify-center">                
                        <a class="link" href="mailto:info@berksmountainbiking.org?subject=volunteer inquiry">
                            <v-btn class="community-button" large>Email us!</v-btn>
                        </a>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
  </template>
  
  <script>
  export default {
    name: "GetInvolved",
  };
  </script>
  
  <style scoped>
    .image-section{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
  .history-section {
    background-color: #3F3F3F;
    padding: 80px 20px 40px 20px;
  }
  .full-height{
    height: 100vh;
  }
  .history-card {
    background-color: #505050;
    padding: 20px 10px;
  }
  .content-1{
    color: #fff !important;
    font-size: 101.1%;
    text-align: center;
  }
  .card-title{
    color: #fff !important;
    font-size: 2.1rem !important;
    text-align: center !important;
  }
  .community-button {
    color: #fff;
    margin: 10px;
    background-color: rgb(119,119,119,1) !important;
    border-color: #444;
    padding: 8px 16px;
    font-weight: 500;
    text-transform: uppercase;
  }
 .link {
    position: relative;
    z-index: 2; 
    color: #FFFFFF; 
  }
  </style>
  