<template>
    <v-dialog v-model="showModal" persistent max-width="1000px">
        <v-card class="mx-auto main-container pa-5" max-width="1000" v-if="post">
            <div class="d-flex justify-end close-btn-container">
                <v-btn icon @click="$emit('close');" >
                    <v-icon large class="red--text">mdi-close</v-icon>
                </v-btn>
            </div>
            <v-img
                :src="`${post.leader_image}`"
                height="400px"
                width="100%"
                contain
            ></v-img>
            <v-card-title class="headline font-weight-bold white-color justify-center text-break">{{post.title}}</v-card-title>
            <v-card-text>
                <div class="date_container">
                    <div class="date white-color">{{ formatDate(post.scheduled_date_time) }}</div>
                    <div class="categories"> - In: {{categories}}</div>
                </div>
            </v-card-text>
            <v-card-text class="white-color text-italic">{{post.leader}}</v-card-text>
            <v-card-text class="white-color content-detail">
                <div v-html="post.content"></div>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>
<script>
    export default{
        data(){
            return{
                postIdFromProp: this.id,
                post: null,
                categories: '',
                showModal: this.openDialog,
            }
        },
        props:{
            id: Number,
            openDialog: Boolean,
        },
        watch:{
            id(newVal){
                if(newVal != -99){
                    this.postIdFromProp = newVal;          
                    this.fetchPost(this.postIdFromProp);
                }
            },
            openDialog(newVal) {
                this.showModal = newVal;
            },
        },
        methods: {
            formatDate(dateString) {
                const date = new Date(dateString);
                return date.toLocaleDateString(undefined, {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                });
            },
            async fetchPost(id){
                try {
                    const response = await fetch(
                        `https://blog-viewer-api.mywebsitebuilder.com/v1.0/664bbe9bd1a149e588acb03840ce89d3/blog/posts/${id}`
                    );
                    const data = await response.json();
                    this.post = data;
                    let categoryStr = ''
                    if (this.post !== null) {
                        this.post.categories && this.post.categories.forEach((category) => {
                            categoryStr += category.category_name + ',';
                        });
                    }
                    this.categories = categoryStr;
                } catch (error) {
                    console.error('Error fetching posts:', error);
                }
            }
        }
    }
</script>
<style scoped>
    .main-container{
        background-color: #3F3F3F;        
    }
    .white-color{
        color: #fff !important;
    }
    .date_container{
        display: flex;
        flex-direction: row;
    }
    .text-italic{
        font-style: italic !important;
    }
    .content-detail{
        font-size: small;
        font-weight: 400;
    }
    .date{
        margin-right: 10px;
        font-weight: bold;
    }
    .categories{
        color: rgb(255, 255, 255, 0.6);
    }
</style>

<style>
    .kv-ee-blog-image {
  text-align: center;
  margin: 10px auto;
  max-width: 500px; /* Optional: Restrict container width */
}

.kv-ee-blog-image img {
  max-width: 100%; /* Ensure image scales within the container */
  height: auto;    /* Maintain aspect ratio */
  display: block;  /* Center the image */
  margin: 0 auto;
}
.v-card__text a{
    color:#fff !important;
}
</style>