<template>
    <v-container fluid class="main-section" fill-height>
        <v-row class="justify-center text-left my-10">
            <v-col cols="12" md="9" class="text-container text-white px-5">
                <v-row>
                    <v-col cols="12" md="6" class="image-section">
                        <div style="opacity: 1; transform: translateY(0px); vertical-align: middle;">
                            <v-img src="@/assets/images/donate/donate.jpg" alt="donate" class="donate-img"
                                max-width="500%" max-height="600px" contain />
                        </div>
                    </v-col>
                    <v-col cols="12" md="6" class="content-section text-white">
                        <h1 class="pb-4">Donate today.</h1>
                        <p class="content">We could not do what we do without our community. As a member-supported
                            non-profit 501(c)(3),
                            our resources solely come from fundraisers, sponsorships, membership revenue, and grants.
                            What does your donation help support?</p>
                        <p class="content">Our many trail stewardship efforts that make Berks County a
                            <a class="highlighter text-white" href="https://www.imba.com/ride/where-to-ride/ride-centers" target="_blank" rel="noopener noreferrer">Bronze Level IMBA Ride Center</a> mountain biking destination.
                        </p>
                        <p class="content">
                            Our trail advocacy work with various land owners, managers, and agencies.
                        </p>
                        <p class="content">
                            Engaging our members and the surrounding community to create exceptional recreational
                            experiences.
                        </p>
                        <p class="content">
                            Efforts to educate and unite youth on supporting our local trail systems together.
                        </p>
                        <p class="content">
                            Support of local businesses and dedication to adding value to our community.
                        </p>
                        <p class="content">
                            Check out our <a class="highlighter text-white" href="/completed-projects" target="_blank" rel="noopener noreferrer">Completed Projects</a> page for more details about what we do. If you would like
                            to make a donation directly to BAMBA, in addition to or in lieu of membership, please use
                            the donate button. If you prefer to donate via check, you can mail it to:
                        </p>
                        <p class="content">
                            <span class="address">
                                <span>PO Box 6121</span>
                                <span>Wyomissing, PA 19610</span>
                            </span>
                        </p>
                        <p class="content">
                            Remember, every donation matters. Thank you!
                        </p>
                        <v-btn class="mt-3" color="white"
                            href="https://www.paypal.com/donate/?hosted_button_id=HG96Z7ZEJ359U" target="_blank">
                            Donate Here
                        </v-btn>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        name: "donate-com",
    };
</script>

<style scoped>
    .main-section {
        position: relative;
        background-image: url('@/assets/images/donate/donate-bg.jpg');
        background-position: center;
        background-size: cover;
        padding: 0 10%;
        color: #fff;
        overflow: hidden;
    }

    .main-section::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(51, 51, 51, 0.78);
        z-index: 0;
    }

    .donate-img {
        z-index: 2;
    }

    .image-section {
        align-content: center;
    }

    .content-section {
        z-index: 1;
    }

    .text-white {
        color: #fff;
    }

    .content {
        font-size: 1.05rem;
    }

    .highlighter {
        font-size: 1.1rem;
        font-weight: 600;
        text-decoration: underline;
    }

    .address {
        display: flex;
        flex-direction: column;
    }
</style>