<template>
    <v-container fluid class="join-section" fill-height>
      <v-row class="d-flex align-center justify-center">
        <v-col cols="12" md="8" class="text-white text-center py-12 content">
          <h2 class="title font-weight-bold mb-6">Why join?</h2>
          <div class="mb-10 text-left">
            <p class="subHeading mb-4">
              Your membership helps accomplish BAMBA’s vision:
            </p>
            <ul class="benefits-list mb-5 content">
              <li>To establish, maintain, enhance, and preserve access to mountain biking trails on local multi-use trail networks.</li>
              <li>To create and enhance strong partnerships with local land managers, organizations, other trail user groups, businesses, and government entities who support multi-use, sustainable trail systems.</li>
              <li>To promote responsible, respectful riding and trail use.</li>
              <li>To support inclusion, wellness, fitness, healthy lifestyles, and friendships for all ages and abilities through mountain biking.</li>
              <li>To educate our community about the many benefits of mountain biking.</li>
            </ul>
            <p class="subHeading mb-4">
              Additional membership benefits include:
            </p>
            <ul class="benefits-list content">
              <li>Opportunities to participate in many local rides varying in terrain and distance</li>
              <li>Ability to actively contribute and be involved in our many projects</li>
              <li>Access to lead a board-supported committee to contribute your project ideas to enhance the local mountain biking community</li>
              <li>A free digital subscription to <em>Mountain Bike Action</em> magazine</li>
              <li>Participation in membership drives with drawings to win great prizes</li>
              <li>A discount on a new Subaru via IMBA’s VIP Program</li>
              <li>Discounts on over 100 brands of bike and outdoor gear through ExpertVoice.com</li>
              <li>15% discount on Water-to-Go bottles</li>
              <li><a class="content" href="https://www.partnerships.getspot.com/imba/" target="_blank" rel="noopener noreferrer">Spot discounted injury insurance</a> for mountain bikers: IMBA members and registered family members get Spot for just $74.99 for an entire year</li>
              <li>15% discount on <a class="content" href="https://www.rlc-mtb.com/" target="_blank" rel="noopener noreferrer">RLC Online MTB Skills Coaching</a> (<a class="content" href="mailto:info@berksmountainbiking.org?subject=RLC Online MTB Coaching inquiry">email us for info</a>)</li>
              <li>5% discount on new bikes, 10% discount on parts and accessories at Shirk's Bike Shop</li>
              <li>10% discount at <a class="content" href="https://jbsbikeshop.com/" target="_blank" rel="noopener noreferrer">JB's Bike Shop</a></li>
              <li>10% discount accessories at <a class="content" href="https://www.trekbikes.com/us/en_US/retail/pottstown/" target="_blank" rel="noopener noreferrer">Trek Bicycle in Pottstown</a></li>
            </ul>
          </div>
          <v-btn class="join-button" @click="openUrl('https://www.imba.com/join/chapter/269063')">
            Join or Renew Here
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </template>
  
  <script>
  export default {
    name: "JoinSection",
    methods:{
        openUrl(url){
            window.open(url, "_blank");
        }
    }
  };
  </script>
  
  <style scoped>
  .join-section {
    position: relative;
    background-image: url('@/assets/images/membership/join-member.jpg');
    background-position: center;
    background-size: cover;
    padding: 0 10%;
    color: #fff;
    overflow: hidden;
  }
  
  .join-section::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(51, 51, 51, 0.78); /* Black overlay with 78% opacity */
    z-index: 1;
  }
  
  .content {
    position: relative;
    z-index: 2; 
    color: #FFFFFF; 
  }
  
  .title {
    font-size: 2.5rem !important;
    color: #FFFFFF;
  }
  
  .benefits-list {
    padding-left: 1.5rem;
    list-style-type: none;
    color: #FFFFFF !important; 
  }
  
  .benefits-list li {
    margin-bottom: 0.5rem;
  }
  
  .join-button {
    font-size: 1rem;
    padding: 0.5rem 2rem;
    color: rgba(51, 51, 51);
    margin: 10px;
    background-color: rgb(247, 241, 241) !important;
    border: 2px solid #444;
    font-weight: 500;
    text-transform: uppercase;
    transition: all 0.3s ease; /* Smooth transition for the hover effect */
  }

  .join-button:hover {
    background-color: #444 !important; /* Darker background on hover */
    color: #fff !important; /* Bright white text on hover */
    border-color: #444 !important; /* Slightly darker border on hover */
  }
  .subHeading{
    font-weight: bold;
    font-size: 150.1%;
  }
  .content{
    font-size: 108.1%;
  }
  </style>
  