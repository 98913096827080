<template>    
    <v-carousel 
        v-if="products !== null"
        cycle
        hide-delimiter-background
        class="custom-carousel"
    >
        <v-carousel-item
        v-for="(product) in products"
        :key="product.id"
        >
        <v-responsive :aspect-ratio="16/9">
            <img :src="require(`@/${product.image}`)" class="carousel-image" />
        </v-responsive>
    </v-carousel-item>
  </v-carousel>
</template>
<script>
    export default {
        data () {
            return {
            products: [
                {
                    id: 1,
                    image:'assets/images/home/carousel-1.jpg'
                },
                {
                    id: 2,
                    image:'assets/images/home/carousel-2.jpg'
                },
                {
                    id: 3,
                    image:'assets/images/home/carousel-3.jpg'
                },
            ]
            }
        },
    }
</script>

<style scoped>
    .custom-carousel {
        max-height: 450px; /* Default max height for larger screens */
    }

    .carousel-image {
        width: 100%;
        max-height: 450px;
        object-fit: cover;
    }

    @media (min-width: 961px) {
        .custom-carousel {
            min-height: 450px;
            max-height: 450px; /* Adjust for large screens */
        }
        .carousel-image {
            min-height: 450px;
            max-height: 450px; /* Adjust for large screens */
            object-fit: cover;
        }
    }
/* Set consistent height for different breakpoints */
@media (max-width: 960px) {
  .custom-carousel {
    min-height: 400px;
    max-height: 400px; /* Adjust for tablets */
  }
  .carousel-image {
    min-height: 400px;
    max-height: 400px; /* Adjust for tablets */
    object-fit: cover;
  } 
}

@media (max-width: 480px) {
  .custom-carousel {
    min-height: 300px;
    max-height: 300px; /* Adjust for small screens */
  }
  .carousel-image {
    min-height: 300px;
    max-height: 300px; /* Adjust for small screens */
    object-fit: cover;
  } 
}
</style>
<style>
    /* Center the arrows vertically on all screen sizes */
    /* .v-window__prev,
    .v-window__next {
      top: 50%;
      transform: translateY(-50%);
    } */
    @media (max-width: 960px) {
        .v-window__prev,
        .v-window__next {
            top: 40%;      
        }
    }
    @media (max-width: 480px) {
        .v-window__prev,
        .v-window__next {
            top: 25%;      
        }
    }
</style>