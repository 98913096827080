<template>
    <v-container fluid class="main-section">
        <v-row class="justify-center text-left my-10">
            <v-col cols="12" md="9" lg="7" class="text-container text-white px-5">
                <h1 class="font-weight-bold mb-5 text-break">Explore our Region</h1>
                <p class="content text-break">
                    In 2015, The City of Reading proudly announced that it has been awarded the national designation of
                    a Bike Friendly Community at the Bronze level by the League of American Bicyclists. In addition, the
                    area received an
                    <a class="highlighter text-white" href="https://www.imba.com/ride/where-to-ride/ride-centers"
                        target="_blank" rel="noopener noreferrer">
                        IMBA Bronze-Level Ride Center
                    </a> Designation! We are really proud of that. The Ride
                    Center™ designation represents IMBA's Model Trail recognition for large-scale mountain bike
                    facilities that offer something for every rider. This designation is designed to support local
                    stakeholders and land managers in their effort to create the ultimate mountain biking destination
                    trail system. Read more about these accomplishments on our
                    <a class="highlighter text-white" href="/completed-projects" target="_blank"
                        rel="noopener noreferrer">Completed Projects page</a> under the
                    2015-2016 archive.
                </p>
                <p class="content text-break">
                    BAMBA promotes responsible and respectful riding of the shared-use trails throughout our region.
                    Everyone is strongly encouraged to understand the unique expectations of the trail network before
                    embarking on a ride. It is a gift, not a privilege, to have access to these beautiful trail systems.
                    Please help us preserve this gift for future generations. Read more about responsible riding
                    <a class="highlighter text-white"
                        href="https://www.imba.com/sites/default/files/content/resources/2020-09/IMBA_Posters_RulesoftheTrail_final.pdf"
                        target="_blank" rel="noopener noreferrer">here</a>.
                </p>
            </v-col>
            <v-col cols="12" md="9" lg="7" class="text-container text-white px-5">
                <v-row justify-center>
                    <v-col cols="12" md="6">
                        <v-card elevation="2" class="card-fixed hover-effect">
                            <v-img :src="require(`@/assets/images/explore-region/img1.jpg`)" alt="explore our region"
                                class="card-image" cover>
                            </v-img>

                            <v-card-title class=" font-weight-bold justify-center text-break">
                                <h3 class="mb-3">Mount Penn Preserve & Antietam Lake Park</h3>
                            </v-card-title>
                            <v-card-subtitle class="text-white subtitle-content pb-0 text-break">
                                Mt. Penn Preserve is almost 40 miles of all mountain trails. If you like rocks, roots
                                and technical trail features this is your place! It is also home to the gravity trail
                                <a class="highlighter-link text-white" href="/current-projects" target="_blank"
                                    rel="noopener noreferrer">Skyline, currently getting some enhancements</a>.
                                <br><a class="highlighter-link text-white"
                                    href="https://www.trailforks.com/region/mount-penn-preserve/" target="_blank"
                                    rel="noopener noreferrer">Click here
                                </a> for trail maps and more information or to add a trail report.
                            </v-card-subtitle>
                            <v-card-subtitle class="text-white subtitle-content mb-3 text-break">
                                Antietam Lake Park is located at the northeast side of Mt. Penn. The lake is surrounded
                                by 665 acres of land and 12 miles of trails.
                                <br><a class="highlighter-link text-white"
                                    href="https://www.trailforks.com/region/antietam-lake-park-37463/" target="_blank"
                                    rel="noopener noreferrer">Click here
                                </a> for trail maps and more
                                information or to add a trail report.
                            </v-card-subtitle>
                        </v-card>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-card elevation="2" class="card-fixed hover-effect">
                            <v-img :src="require(`@/assets/images/explore-region/img2.jpg`)" alt="explore our region"
                                class="card-image" cover>
                            </v-img>

                            <v-card-title class=" font-weight-bold justify-center text-break">
                                <h3 class="mb-3">Blue Marsh Lake & Skills Area</h3>
                            </v-card-title>
                            <v-card-subtitle class="text-white subtitle-content pb-0 text-break">
                                Blue Marsh Lake has over 35 miles of cross country trails. There are options to ride
                                sections or to ride an entire 30 mile loop around the beautiful lake.
                            </v-card-subtitle>
                            <v-card-subtitle class="text-white subtitle-content mb-3 text-break">
                                BAMBA has partnered with the US Army Corps of Engineers in a multi-phase trail
                                restoration program. Be sure to check out the new Skills Area!
                                <a class="highlighter-link text-white"
                                    href="https://storage.googleapis.com/production-constantcontact-v1-0-3/163/654163/6biOFsfd/9633c31e6584476aa5f2030e5548517b?fileName=BAMBA_BlueMarsh_Flow_Map_REV_082222.pdf"
                                    target="_blank" rel="noopener noreferrer">CLICK HERE</a> for a downloadable trail
                                map and more information.
                            </v-card-subtitle>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row justify-center>
                    <v-col cols="12" md="6">
                        <v-card elevation="2" class="card-fixed hover-effect">
                            <v-img :src="require(`@/assets/images/explore-region/img3.jpg`)" alt="explore our region"
                                class="card-image" cover>
                            </v-img>

                            <v-card-title class=" font-weight-bold justify-center text-break">
                                <h3 class="mb-3">Neversink Mountain Preserve</h3>
                            </v-card-title>
                            <v-card-subtitle class="text-white subtitle-content pb-0 text-break">
                                Neversink Mountain Preserve is over 22 miles of long climbs and old-school singletrack.
                                There are also many historical sites throughout the Preserve, and an amazing overlook on
                                the south side.
                            </v-card-subtitle>
                            <v-card-subtitle class="text-white subtitle-content mb-3 text-break">
                                Be sure to <a class="highlighter-link text-white"
                                    href="https://www.trailforks.com/region/neversink-mountain-preserve/"
                                    target="_blank" rel="noopener noreferrer">click here</a> for trail maps and parking
                                information or to add a trail report. There are over a dozen
                                private land owners within the Preserve; please respect all private property boundaries.
                            </v-card-subtitle>
                        </v-card>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-card elevation="2" class="card-fixed hover-effect">
                            <v-img :src="require(`@/assets/images/explore-region/img4.jpg`)" alt="explore our region"
                                class="card-image" cover>
                            </v-img>

                            <v-card-title class=" font-weight-bold justify-center text-break">
                                <h3 class="mb-3">French Creek State Park</h3>
                            </v-card-title>
                            <v-card-subtitle class="text-white subtitle-content pb-0 text-break">
                                NFrench Creek State Park offers 25 miles of rocky, technical riding. Straddling the
                                Schuylkill Highlands, the 7,730-acre park is the largest block of contiguous forest
                                between Washington D.C. and New York City. The forests, lakes, wetlands, and fields are
                                a destination for the people of southeast Pennsylvania to hike, fish, camp, and bike.
                                <a class="highlighter-link text-white"
                                    href="https://www.trailforks.com/region/french-creek-state-park/" target="_blank"
                                    rel="noopener noreferrer">Click here</a> for trail maps and more information or to add a trail report.
                            </v-card-subtitle>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row justify-center>
                    <v-col cols="12" md="6">
                        <v-card elevation="2" class="card-fixed hover-effect">
                            <v-img :src="require(`@/assets/images/explore-region/img5.jpg`)" alt="explore our region"
                                class="card-image" cover>
                            </v-img>

                            <v-card-title class=" font-weight-bold justify-center text-break">
                                <h3 class="mb-3">Schuylkill River Trail (SRT)</h3>
                            </v-card-title>
                            <v-card-subtitle class="text-white subtitle-content text-break">
                                <a class="highlighter-link text-white"
                                    href="https://www.10best.com/awards/travel/best-urban-trail/" target="_blank"
                                    rel="noopener noreferrer">
                                    The SRT was rated #1 urban trail in the USA in 2015.
                                </a>
                                It is a 120 mile gravel multi-use
                                trail extending from Schuylkill County to Philadelphia. Most of the trail is built over
                                abandoned railroad lines with some sections still in progress. The surface is mostly
                                crushed stone with some paved surfaces. It is a popular throughway for many mountain
                                bikers, as it connects Neversink Mountain to Reading by the Exeter Scenic Trail. It also
                                connects Reading to Blue Marsh by
                                <a class="highlighter-link text-white"
                                    href="https://visitpaamericana.com/partner/grings-mill-recreation-area/"
                                    target="_blank" rel="noopener noreferrer">
                                    Gring's Mill
                                </a>
                                using the
                                <a class="highlighter-link text-white"
                                    href="https://www.co.berks.pa.us/Dept/Parks/Pages/UnionCanalTowpathTrail.aspx"
                                    target="_blank" rel="noopener noreferrer">
                                    Union Canal Towpath Trail
                                </a>.
                                <br>
                                If you are looking for a scenic gravel ride, this is your spot!
                                <a class="highlighter-link text-white"
                                    href="https://schuylkillriver.org/schuylkill-river-trail/" target="_blank"
                                    rel="noopener noreferrer">
                                    Click here
                                </a>
                                for trail maps and more information.
                            </v-card-subtitle>
                        </v-card>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-card elevation="2" class="card-fixed hover-effect">
                            <v-img :src="require(`@/assets/images/explore-region/img6.jpg`)" alt="explore our region"
                                class="card-image" cover>
                            </v-img>

                            <v-card-title class=" font-weight-bold justify-center text-break">
                                <h3 class="mb-3">Gravity Trail</h3>
                            </v-card-title>
                            <v-card-subtitle class="text-white subtitle-content text-break">
                                The Gravity Trail used to be a gravity railroad and has been repurposed as a 4 mile
                                multi-use trail. It is a popular way to connect Antietam Lake Park and Neversink
                                Mountain Preserve, often referred to as a "Double Mountain Ride". Sections of the rail
                                beds are still noticeable in certain areas. It passes through several city-owned parks.
                                <br/>
                                <a class="highlighter-link text-white"
                                    href="https://visitpaamericana.com/partner/gravity-trail/" target="_blank"
                                    rel="noopener noreferrer">
                                    Click here
                                </a> for more information and <a class="highlighter-link text-white"
                                href="https://berksnature.org/wp-content/uploads/2021/06/gravity-trail-map.pdf" target="_blank"
                                rel="noopener noreferrer">
                                click here
                            </a> for trail map.
                            </v-card-subtitle>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        name: "explore-our-region",
    };
</script>

<style scoped>
    .main-section {
        background-color: #3F3F3F;
    }

    .text-white {
        color: #fff !important;
    }

    .content {
        font-size: 1.2rem;
    }

    .subtitle-content {
        font-size: 1rem;
    }

    .highlighter {
        font-size: 1.3rem;
        font-weight: 600;
        text-decoration: underline;
    }

    .highlighter-link {
        font-weight: 600;
        text-decoration: underline;
    }

    .card-image {
        max-height: 200px;
        min-height: 200px;
        width: 100%;
        object-fit: cover;
    }

    .card-fixed {
        background-color: #505050;
        color: #fff;
        height: calc(100% - 30px);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        transition: transform 0.3s ease, box-shadow 0.3s ease;
        padding-bottom: 30px;
    }

    .card-fixed.hover-effect:hover {
        transform: scale(1.05);
        box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
    }
</style>