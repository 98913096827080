<template>
    <div>
      <youth-mountain-biking />
    </div>
  </template>
  <script>
    import YouthMountainBiking from '../youth-mountain-biking/youth-mountain-biking';
  
    export default{
        components: {
            YouthMountainBiking
        },
    }
  </script>