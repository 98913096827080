<template>
    <v-container class="history-section" fluid>
      <v-row align="center" justify="center">
        <v-col cols="12" md="6" class="history-image">
          <v-img src="@/assets/images/home/history-1.jpg" alt="History Image" class="elevation-2" />
        </v-col>
        <v-col cols="12" md="6">
          <v-card class="history-card" elevation="3">
            <v-card-title class="headline font-weight-bold card-title">Our History</v-card-title>
            <v-card-text class="content">
              BAMBA was established in 2012 as the first <a href='https://www.imba.com/' target='_blank' class="content">Chapter of IMBA</a> in Berks County, PA. Today, BAMBA is an
              independent IMBA Affiliate, non-profit educational trail association whose mission is to create, enhance,
              and preserve great trail experiences for mountain bikers in Berks County, Pennsylvania.
            </v-card-text>
            <v-card-actions>
              <v-btn class="community-button" large @click="$router.push('/who-we-are')">Read more about who we are here</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </template>
  
  <script>
  export default {
    name: "HistorySection",
  };
  </script>
  
  <style scoped>
  .history-section {
    background-color: #2d2d2d;
    padding: 40px 20px;
  }
  
  .history-image {
    display: flex;
    justify-content: center;
  }
  
  .history-card {
    background-color: #3d3d3d;
    padding: 20px;
  }
  
  .content{
    color: #fff !important;
    font-size: 110.1%;
  }
  .card-title{
    color: #fff !important;
    font-size: 2rem !important;
  }
  .history-card .v-btn {
    margin-top: 15px;
  }
  .community-button {
    color: #fff;
    margin: 10px;
    background-color: rgb(119,119,119,1) !important;
    border-color: #444;
    padding: 8px 16px;
    font-weight: 500;
    text-transform: uppercase;
  }
  </style>
  