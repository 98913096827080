<template>
    <v-container class="main_v_container py-10" fluid>
      <v-row justify="center" class="py-8">
        <v-col cols="12" md="9">
          <v-row>
            <v-col cols="12" class="header_container">
              <h1 class="mb-2">Check out what's going on. Visit us on Facebook!</h1>
              <h4>See what we've posted on our timeline or just send us a message.</h4>
            </v-col>
            <v-col cols="12" class="frame_container">
              <iframe
                name="f2b5bb85bb5c25"
                title="fb:page Facebook Social Plugin"
                frameborder="0"
                allowtransparency="true"
                allowfullscreen="true"
                scrolling="no"
                allow="encrypted-media"
                class="responsive-iframe"
                src="https://www.facebook.com/plugins/page.php?href=https://www.facebook.com/BerksMountainBiking/&amp;tabs=timeline,,&amp;width=500&amp;height=1000&amp;small_header=false&amp;adapt_container_width=true&amp;hide_cover=true&amp;show_facepile=&amp;appId=620549068053083"
              ></iframe>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </template>
  
  <script>
  export default {
    data() {
      return {};
    },
  };
  </script>
  
  <style scoped>
  .main_v_container {
    position: relative;
    background-image: url('@/assets/images/news/fb-bg.jpg');
    background-position: center;
    background-size: cover;
    padding: 0 10%;
    color: #fff;
    overflow: hidden;
  }
  .header_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .frame_container {
    display: flex;
    justify-content: center;
    text-align: center;
  }
  .responsive-iframe {
    width: 100%; /* Takes full width of its container */
    height: 100vh; /* Adjusts height dynamically */
    max-width: 500px; /* Optional: Restricts iframe to a maximum width */
    border: none;
  }
  </style>
  