<template>
    <v-container fluid class="main-section">
        <v-row class="justify-center text-left my-10">
            <v-col cols="12" md="9" lg="7" class="text-container text-white px-5">
                <h1 class="font-weight-bold mb-5">
                    Do you know a child passionate about mountain biking?
                </h1>
                <p class="content">
                    WAMTB and TVMTB interscholastic mountain bike teams are open to boys and girls in 6th through 12th
                    grades from multiple school districts in Berks County. Riders have the opportunity to participate in
                    organized races across Pennsylvania. Coaches will guide riders in honing their bike handling and
                    bike maintenance skills while focusing on fun and safety. Our goal is to create life-long athletes
                    who have a respect and love of cycling and the outdoors.
                </p>
            </v-col>
            <v-col cols="12" md="9" lg="7" class="text-container text-white px-5">
                <v-row>
                    <v-col cols="12" sm="6" class="d-flex">
                        <v-card outlined class="pa-4 mb-4 card-main hover-effect">
                            <v-img src="@/assets/images/youth-mb/img1.jpg" alt="WAMTB" class="card-image"></v-img>
                            <v-card-text>
                                <p class="content">
                                    <a href="https://wamtb.org/"
                                        class="text-decoration-underline font-weight-bold text-white" target="_blank">
                                        click here to learn more about WAMTB
                                    </a>
                                </p>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col cols="12" sm="6" class="d-flex">
                        <v-card outlined class="pa-4 mb-4 card-main hover-effect">
                            <v-img src="@/assets/images/youth-mb/img2.jpg" alt="TVMTB" class="card-image"></v-img>
                            <v-card-text>
                                <p class="content">
                                    <a href="https://tvmtb.com/"
                                        class="text-decoration-underline font-weight-bold text-white" target="_blank">
                                        click here to learn more about TVMTB
                                    </a>
                                </p>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        name: "youth-mountain-biking",
    };
</script>

<style scoped>
    .main-section {
        background-color: #3F3F3F;
    }

    .text-white {
        color: #fff;
    }

    .content {
        font-size: 1.2rem;
    }

    .card-main {
        background-color: #505050;
    }
    .card-main.hover-effect:hover {
        transform: scale(1.05);
        /* Slightly enlarges the card */
        box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
        /* Adds a shadow for emphasis */
    }
    .card-image {
        max-height: 250px;
        object-fit: contain;
    }

    

    .text-decoration-underline {
        text-decoration: underline;
    }

    .font-weight-bold {
        font-weight: bold;
    }
</style>