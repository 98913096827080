<template>
    <v-container class="history-section" fluid>
      <v-row align="center" justify="center">
        <v-col cols="12" md="6" class="history-image1">
            <v-img
                src="@/assets/images/volunteer/get-return.jpg"
                alt="Get Return"
                max-width="600%"
                max-height="600px"
                contain
            />
        </v-col>
        <v-col cols="12" md="6">
          <v-card class="history-card" elevation="3">
            <v-card-title class="headline font-weight-bold card-title justify-center text-break">What You Get in Return</v-card-title>
            <v-card-text class="content-1 mt-3 text-break">
                Engagement with our awesome MTB community<br>
                BAMBA swag<br>
               This glowy green trail crew shirt<br>
               Free beer (or soda) and fist bumps<br>
               Great friendships<br>A great sense of accomplishment<br>Entrance into the holiday drawing
            </v-card-text>
            <v-card-text class="content">
                Did you know that all member volunteers have their name entered in a drawing for EACH DAY they volunteer? That means if you come out to 5 volunteer events in one year, your name will be entered into the drawing 5 times. Prizes will be awarded at our Holiday Social in December!
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </template>
  
  <script>
  export default {
    name: "GetReturn",
  };
  </script>
  
  <style scoped>
  .history-section {
    background-color: #2d2d2d;
    padding: 40px 20px;
  }
  
  .history-image {
    display: flex;
    justify-content: center;
  }
  
  .history-card {
    background-color: #3d3d3d;
    padding: 20px;
  }
  
  .content{
    color: #fff !important;
    font-size: 90.1%;
    text-align: center;
  }
  .content-1{
    color: #fff !important;
    font-size: 110.1%;
    text-align: center;
  }
  .card-title{
    color: #fff !important;
    font-size: 2.1rem !important;
    text-align: center !important;
  }
  .history-card .v-btn {
    margin-top: 15px;
  }
  .community-button {
    color: #fff;
    margin: 10px;
    background-color: rgb(119,119,119,1) !important;
    border-color: #444;
    padding: 8px 16px;
    font-weight: 500;
    text-transform: uppercase;
  }
  </style>
  