<template>
    <div>
      <youth-mountain-biking />
    </div>
  </template>
  
  <script>
   import YouthMountainBiking from '../../components/pages/youth-mountain-biking-page';
  
    export default {
      name: 'youth-mountain-biking-view',
      metaInfo: {
        title: 'Youth Mountain Biking - Berks Area Mountain Biking Association',
      },
      components: {
        YouthMountainBiking
      },
    }
  </script>
  