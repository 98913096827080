<template>
    <div>
      <v-row>
        <v-col cols="12" class="pb-0">
          <explore-region />
        </v-col>
        <!-- <v-col cols="12" class="pt-0 pb-0" >
            <trail-issue />
        </v-col> -->
      </v-row>
      
        
    </div>
  </template>
  <script>
    import ExploreRegion from '../explore-our-region/explore-our-region-com';
    //import TrailIssue from '../explore-our-region/trail-issue-com.vue';
  
    export default{
        components: {
            ExploreRegion,
            //TrailIssue
        },
    }
  </script>