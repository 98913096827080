<template>
    <div>
      <volunteer-page />
    </div>
  </template>
  
  <script>
   import VolunteerPage from '../../components/pages/volunteer-page-comp';
  
    export default {
      name: 'volunteer-view',
      metaInfo: {
        title: 'Volunteer - Berks Area Mountain Biking Association',
      },
      components: {
        VolunteerPage
      },
    }
  </script>
  