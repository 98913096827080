<template>
    <v-container class="history-section" fluid>
      <v-row align="center" justify="center">
        <v-col cols="12" md="6">
            <v-img
                src="@/assets/images/volunteer/volunteer-position.jpg"
                alt="Get Return"
                max-width="600%"
                max-height="600px"
                contain
            />
        </v-col>
        <v-col cols="12" md="6">
            <v-card class="history-card pa-6 text-center">
                <v-card-title class="headline font-weight-bold card-title justify-center text-break">OPEN VOLUNTEER POSITIONS</v-card-title>
                <v-card-text class="content-1 mt-3 text-break">
                    RIDE HOSTS FOR TUESDAY NIGHT RIDES (TNR all abilities) and THURSDAY NIGHT RIDES (ThNR advanced)
                </v-card-text>
                <v-card-text class="content text-break">
                    Weekend rides and other days of the week are welcome too! We help provide routes and support.
                </v-card-text>
                <v-card-text class="content-1 mt-3 text-break">
                    FUNDING STRATEGIES COMMITTEE
                </v-card-text>
                <v-card-text class="content text-break">
                    Help raise revenues and obtain sponsors.
                </v-card-text>
                <!-- <v-card-text class="content-1 mt-3 text-break">
                    TECHNOLOGY COMMITTEE
                </v-card-text>
                <v-card-text class="content text-break">
                    Help with administration of technology platforms and updates to our website.
                </v-card-text> -->
                <v-card-text class="content-1 mt-3 text-break">
                    TRAIL DEVELOPMENT & MAINTENANCE COMMITTEE
                </v-card-text>
                <v-card-text class="content text-break">
                    Become a Trail Crew Leader and help organize and lead trail maintenance days!
                </v-card-text>
              </v-card>
        </v-col>
      </v-row>
    </v-container>
  </template>
  
  <script>
  export default {
    name: "volunteer-position",
  };
  </script>
  
  <style scoped>    
  .history-section {
    background-color: #2d2d2d;
    padding: 40px 20px;
  }
  
  .history-image {
    display: flex;
    justify-content: center;
  }
  
  .history-card {
    background-color: #3d3d3d;
  }
  
  .content{
    color: #fff !important;
    font-size: 99.1%;
    text-align: center;
    padding-top: 0px !important;
  }
  .content-1{
    color: #fff !important;
    font-size: 110.1%;
    text-align: center;
    font-weight: bold;
    padding-bottom: 8px !important;
  }
  .card-title{
    color: #fff !important;
    font-size: 2.1rem !important;
    text-align: center !important;
  }
  </style>
  