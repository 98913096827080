<template>
    <div>
      <donate-com />
    </div>
  </template>
  <script>
    import DonateCom from '../donate/donate-com';
  
    export default{
        components: {
            DonateCom
        },
    }
  </script>