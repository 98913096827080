<template>
    <div>
      <membership-page />
    </div>
  </template>
  
  <script>
   import MembershipPage from '../../components/pages/member-page-comp';
  
    export default {
      name: 'membership-view',
      metaInfo: {
        title: 'Membership - Berks Area Mountain Biking Association',
      },
      components: {
        MembershipPage
      },
    }
  </script>
  