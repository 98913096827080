<template>
    <div v-if="event && event.registration_needed === 1">
        <v-tooltip top v-if="!isRegistrationOpen(event)">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              text
              class="card-button-closed"
            >
              {{ getRegistrationBtnText(event) }}
            </v-btn>
          </template>
          <span>Registration is closed. Please contact the Organizer.</span>
        </v-tooltip>
        <v-btn
          v-else
          text
          class="card-button-blink"
          @click="btnClick"
        >
          {{ getRegistrationBtnText(event) }}
        </v-btn>
    </div>
</template>

<script>
    export default {
        data() {
            return{}
        },
        props:{
            event: {
                type: Object,
                default: null,
            },
        },
        methods:{
            btnClick(){
                this.$emit('onButtonClick', this.event);
            },
            getRegistrationBtnText(event) {
                return this.isRegistrationOpen(event) ? 'Register your car(s)' : 'Registration Closed';
            },
            isRegistrationOpen(event) {
                let res = true;
                if (event.registration_needed === 1) {
                    if (event.remaining_registration !== null && event.remaining_registration !== -99 && event.remaining_registration === 0) {
                    res = false;
                    }
                } else {
                    res = false;
                }
                return res;
            },
        }
    }
</script>
<style scoped>
    .card-button-blink {
        font-size: 13px !important;
        color: #a5870e;
        padding: 0 5px !important;
        animation: blink 1s infinite;
    }
    .card-button-closed {
        font-size: 13px !important;
        color: #ffffff;
        background-color: #c0c0c0;
        cursor: not-allowed;
    }
</style>