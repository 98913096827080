<template>
    <div>
      <v-row>
        <v-col cols="12" class="pt-0 pb-0" >
            <completed-project-section2 />
        </v-col>
        <v-col cols="12" class="pt-0 pb-0">
          <completed-project-section3 />
        </v-col>
        <v-col cols="12" class="pt-0 pb-0">
          <completed-project-repaving />
        </v-col>
        <v-col cols="12" class="pt-0 pb-0">
          <completed-project-picnic-area />
        </v-col>
      </v-row>
      <completed-projects />
    </div>
  </template>
  <script>
    import CompletedProjects from '../projects/completed-projects/completed-projects';
    import CompletedProjectSection2 from '../projects/completed-projects/completed-project-section2';
    import CompletedProjectSection3 from '../projects/completed-projects/completed-project-section3';
    import CompletedProjectRepaving from '../projects/completed-projects/completed-project-repaving';
    import CompletedProjectPicnicArea from '../projects/completed-projects/completed-project-picnic-area';
    export default{
        components: {
            CompletedProjects,
            CompletedProjectSection2,
            CompletedProjectSection3,
            CompletedProjectRepaving,
            CompletedProjectPicnicArea
        },
    }
  </script>