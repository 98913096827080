import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/volunteer',
    name: 'volunteer-view',
    component: () => import('../views/volunteer/volunteer-view.vue')
  },
  {
    path: '/membership',
    name: 'membership-view',
    component: () => import('../views/membership/membership-view.vue')
  },
  {
    path: '/committee-participation',
    name: 'committee-participation-view',
    component: () => import('../views/committee-participation/committee-participation-view.vue')
  },
  {
    path: '/who-we-are',
    name: 'who-we-are-view',
    component: () => import('../views/who-we-are/who-we-are-view.vue')
  },  
  {
    path: '/news',
    name: 'news-view',
    component: () => import('../views/news/news-view.vue')
  },
  {
    path: '/current-projects',
    name: 'current-project-view',
    component: () => import('../views/projects/current-project-view.vue')
  },
  {
    path: '/completed-projects',
    name: 'completed-projects-view',
    component: () => import('../views/projects/completed-projects-view.vue')
  },
  {
    path: '/get-involved',
    name: 'get-involved-view',
    component: () => import('../views/get-involved/get-involved-view.vue')
  },
  {
    path: '/sponsorship',
    name: 'sponsorship-view',
    component: () => import('../views/sponsorship/sponsorship-view.vue')
  },
  {
    path: '/youth-mountain-biking',
    name: 'youth-mountain-biking-view',
    component: () => import('../views/youth-mountain-biking/youth-mountain-biking-view.vue')
  },
  {
    path: '/donate',
    name: 'donate-view',
    component: () => import('../views/donate/donate-view.vue')
  },
  {
    path: '/explore-our-region',
    name: 'explore-our-region-view',
    component: () => import('../views/explore-our-region/explore-our-region-view.vue')
  },
  {
    path: '/events',
    name: 'events-view',
    component: () => import('../views/events/events-view.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
