<template>
  <v-app>
    <header-comp />
    <v-main class="main-content bg-image">
      <router-view/>
    </v-main>
    <footer-comp/>
  </v-app>
</template>

<script>
import HeaderComp from './components/header-comp';
import FooterComp from './components/footer-comp';
export default {
  name: 'App',

  data() {
    return {
      
    };
  },
  components: {
    HeaderComp,
    FooterComp,
  },
};
</script>

<style scoped>
  .main-content {
    min-height: 100vh; /* Ensure the main content area stretches to full height */
    display: flex;
    flex-direction: column;
  }
  </style>
  <style>
    .text-break {
      overflow-wrap: break-word;
      word-wrap: break-word;
      word-break: break-word;
 }
  </style>
