<template>
  <div>
    <home-page />
  </div>
</template>

<script>
 import HomePage from '../components/pages/home-page-comp';

  export default {
    name: 'Home-vue',
    metaInfo: {
      title: 'Home - Berks Area Mountain Biking Association',
    },
    components: {
      HomePage
    },
  }
</script>
