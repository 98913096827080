<template>
    <v-container class="stats-section" fluid>
      <v-row justify="center">
        <v-col
          v-for="(stat, index) in stats"
          :key="index"
          cols="6"
          sm="4"
          md="2"
          class="text-center stat-item"
        >
          <p class="stat-number">{{ stat.number }}</p>
          <p class="stat-description">{{ stat.description }}</p>
        </v-col>
      </v-row>
    </v-container>
  </template>
  
  <script>
  export default {
    name: "StatsSection",
    data() {
      return {
        stats: [
          { number: "7.5", description: "Miles of trail built in 2021" },
          { number: "4+", description: "Miles of trail built in 2022" },
          { number: "1,218", description: "Volunteer hours in 2021" },
          { number: "1,014", description: "Volunteer hours in 2022" },
          { number: "1229", description: "Volunteer hours in 2023" },
          { number: "200+", description: "Miles of trails in Berks Cty" },
        ],
      };
    },
  };
  </script>
  
  <style scoped>
    .stats-section {
        background-image: url('@/assets/images/home/stats.jpg');
        background-position: center;
        background-size: cover;
        background-color: black !important;
        opacity: 0.84;
      /* background: linear-gradient(180deg, rgba(54, 54, 54, 0.9), rgba(15, 15, 15, 0.9)); */
      color: #fff;
      padding: 40px 20px;
    }
    
    .stat-item {
      margin-bottom: 20px;
    }
    
    .stat-number {
      font-size: 1.5rem;
      font-weight: bold;
      margin: 0;
    }
    
    .stat-description {
      font-size: 1rem;
      margin: 0;
    }
    </style>
    
  