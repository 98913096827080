<template>
    <v-container fluid class="main-section">
        <v-row class="justify-center text-left my-10">
            <v-col cols="12" md="9" lg="7" class="text-container text-white px-5">
                <h1 class="font-weight-bold mb-5">Thank you to all of our Partners, Supporters and Sponsors!</h1>
                <p class="content">We are truly grateful for your support of our vision and many contributions to our
                    various projects, programs and events.
                </p>
                <p class="content">
                    Our partners have generously donated funds, materials and effort. We encourage our community to
                    support them in return. We would not be where we are today without their advocacy!
                </p>
            </v-col>
            <v-col cols="12" md="9" lg="7" class="text-container px-5">
                <v-row class="gap-2">
                    <v-col v-for="(sponser, index) in sponsors" :key="index" cols="6" sm="4" md="3" lg="3"
                        class="d-flex justify-center align-center pa-2">
                        <v-img :src="require(`@/assets/images/sponsorship/sponsors/${sponser.image}`)" alt="Sponsor"
                            class="bod_image" cover aspect-ratio="1"></v-img>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        name: "section-4",
        data() {
            return {
                sponsors: [
                    { id: 1, image: "s1.jpg" },
                    { id: 2, image: "s2.jpg" },
                    { id: 3, image: "s3.jpg" },
                    { id: 4, image: "s4.jpg" },
                    { id: 5, image: "s5.jpg" },
                    { id: 6, image: "s6.jpg" },
                    { id: 7, image: "s7.jpg" },
                    { id: 8, image: "s8.jpg" },
                    { id: 9, image: "s9.jpg" },
                    { id: 10, image: "s10.jpg" },
                    { id: 11, image: "s11.jpg" },
                    { id: 12, image: "s12.jpg" },
                    { id: 13, image: "s13.jpg" },
                    { id: 14, image: "s14.jpg" },
                    { id: 15, image: "s15.jpg" },
                    { id: 16, image: "s16.jpg" },
                    { id: 17, image: "s17.jpg" },
                    { id: 18, image: "s18.jpg" },
                    { id: 19, image: "s19.jpg" },
                    { id: 20, image: "s20.jpg" },
                    { id: 21, image: "s21.jpg" },
                    { id: 22, image: "s22.jpg" },
                    { id: 23, image: "s23.jpg" },
                ],
            };
        },
    };
</script>

<style scoped>
    .main-section {
        background-color: #333333;
    }

    .text-white {
        color: #fff;
    }

    .content {
        font-size: 1.2rem;
    }

    .bod_image {
        max-width: 200px;
        /* Default max width */
        border-radius: 8px;
        /* Optional for rounded corners */
        transition: max-width 0.3s ease;
        /* Smooth resizing */
    }

    /* Hover Effect: Slightly enlarge the image */
    .bod_image:hover {
        transform: scale(1.1);
        /* Enlarge the image */
        box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.3);
        /* Add shadow for depth */
    }

    /* Adjust max-width for different screen sizes */
    @media (max-width: 1200px) {
        .bod_image {
            max-width: 200px;
        }
    }

    @media (max-width: 992px) {
        .bod_image {
            max-width: 180px;
        }
    }

    @media (max-width: 768px) {
        .bod_image {
            max-width: 150px;
        }
    }

    @media (max-width: 576px) {
        .bod_image {
            max-width: 140px;
        }
    }
</style>