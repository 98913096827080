<template>
    <div>
      <v-row>
        <v-col cols="12" class="pb-0">
          <news-section />
        </v-col>
        <v-col cols="12" class="pt-0 pb-0" >
            <fb-section />
        </v-col>
      </v-row>
        
    </div>
  </template>
  <script>
    import NewsSection from '../news/news-section';
    import FbSection from '../news/fb-section';
  
    export default{
        components: {
            NewsSection,
            FbSection,
        },
    }
  </script>