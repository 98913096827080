<template>
    <v-container>
        <!-- Material Type Radio Buttons -->
        <v-row>
            <v-col cols="12">
                <v-radio-group v-model="selectedMaterial" row label="Material Type: ">
                    <v-radio label="Steel/Steel" value="Steel/Steel"></v-radio>
                    <v-radio label="Steel/Bronze" value="Steel/Bronze"></v-radio>
                </v-radio-group>
            </v-col>
        </v-row>

        <v-row>
            <!-- Input fields for constants and variables -->
             <v-col cols="12">
                <v-form ref="form" v-model="valid">
                    <v-row>
                        <v-col cols="12" md="6">
                            <v-select label="Load Direction (b1)" :items="b1Options" v-model="b1" required></v-select>
                            <v-select label="Temperature Factor (b2)" :items="b2Options" v-model="b2" required></v-select>
                            <v-select label="Sliding Factor (b3)" :items="b3Options" v-model="b3" required></v-select>
                            <v-text-field label="Mean Sliding Velocity (v)" v-model.number="v" required :rules="[validateRange(0.002, 0.1)]"></v-text-field>
                            <!-- Velocity Factor (b4) Dropdown -->
                            <!-- <v-select
                                v-if="selectedMaterial === 'Steel/Bronze'"
                                label="Velocity Factor (b4 - Steel/Bronze)"
                                :items="b4OptionsSteelBronze"
                                v-model="b4"
                                required
                            ></v-select>
                            <v-select
                                v-if="selectedMaterial === 'Steel/Steel'"
                                label="Velocity Factor (b4 - Steel/Steel)"
                                :items="b4OptionsSteelSteel"
                                v-model="b4"
                                required
                            ></v-select> -->
                            <v-text-field
                                label="Velocity Factor (b4)"
                                v-model="b4Label"
                                readonly
                                outlined
                                style="background-color: #f5f5f5; pointer-events: none;"
                            ></v-text-field>
                            <!-- Angle of Oscillation Factor (b5) Dropdown -->
                            <v-select
                                v-if="selectedMaterial === 'Steel/Bronze'"
                                label="Angle of Oscillation Factor (b5 - Steel/Bronze)"
                                :items="b5OptionsSteelBronze"
                                v-model="b5"
                                required
                            ></v-select>
                            <v-select
                                v-if="selectedMaterial === 'Steel/Steel'"
                                label="Angle of Oscillation Factor (b5 - Steel/Steel)"
                                :items="b5OptionsSteel"
                                v-model="b5"
                                required
                            ></v-select>                
                        </v-col>

                        <v-col cols="12" md="6">
                            <v-text-field label="Specific Bearing Load (p)" v-model.number="p" :rules="[validateP, validateRange(10, 50)]" required @blur="enforceMinP"></v-text-field>
                            <v-text-field label="Relubrication Interval (N)" v-model.number="N" required :rules="[validateRange(1, 1000)]"></v-text-field>
                            <!-- Readonly and styled H field -->
                            <v-text-field
                                label="Frequency of relubrication (H)"
                                v-model.number="H"
                                readonly
                                outlined
                                required :rules="[validateRange(1, 50)]"
                                style="background-color: #f5f5f5; pointer-events: none;"
                            ></v-text-field>
                            <v-text-field
                                label="Relubrication Frequency Factor (fH)"
                                v-model.number="fH"
                                readonly
                                outlined
                                required :rules="[validateRange(1, 5.5)]"
                                style="background-color: #f5f5f5; pointer-events: none;"
                            ></v-text-field>
                            <!-- Oscillation Angle Factor (fβ) Dropdown -->
                            <v-select
                                v-if="selectedMaterial === 'Steel/Bronze'"
                                label="Oscillation Angle Factor (fβ - Steel/Bronze)"
                                :items="fBetaOptionsSteelBronze"
                                v-model="fBeta"
                                required
                            ></v-select>
                            <v-select
                                v-if="selectedMaterial === 'Steel/Steel'"
                                label="Oscillation Angle Factor (fβ - Steel/Steel)"
                                :items="fBetaOptionsSteel"
                                v-model="fBeta"
                                required
                            ></v-select>

                            
                            <v-text-field label="Frequency of Oscillation (f)" v-model.number="f" required :rules="[validateRange(1, 1000)]"></v-text-field>
                        </v-col>
                    </v-row>
                </v-form>
            </v-col>
        </v-row>

        <v-btn color="primary" @click="calculate" :disabled="!valid">Calculate</v-btn>
        <v-btn color="secondary" @click="reset" class="ml-4">Reset</v-btn>

        <!-- Output results -->
        <v-card v-if="results" class="mt-4">
            <v-card-text>
                <p>Basic Rating Life (Initial Lubrication): {{ results.Gh }} hours</p>
                <p>Basic Rating Life (Regular Relubrication): {{ results.GhN }} hours</p>
                <p>Basic Rating Life (Oscillations): {{ results.GN }}</p>
                <p>Frequency of Relubrication: {{ results.H }} hours</p>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
export default {
    data() {
        return {
            valid: false,
            b1: null,
            b2: null,
            b3: null,
            b4: null,
            b5: null,
            fBeta: null,
            fH: null,
            p: 10,
            v: 0.002,
            f: null,
            N: null,
            H: null,
            b4Label: '',
            results: null,

            // Dropdown options
            b1Options: [
                { text: "Constant Direction", value: 1 },
                { text: "Alternating Direction", value: 2 },
            ],
            b2Options: [
                { text: "≤ 120°C", value: 1.0 },
                { text: "120°C - 160°C", value: 0.9 },
                { text: "160°C - 180°C", value: 0.8 },
            ],
            b3Options: [
                { text: "10 - 20 mm", value: 1.0 },
                { text: "21 - 50 mm", value: 1.5 },
                { text: "51 - 100 mm", value: 2.0 },
                { text: "101 - 200 mm", value: 3.0 },
                { text: "201 - 500 mm", value: 3.5 },
            ],

            b4OptionsSteelSteel: [
                { text: "0.002 - 0.005 m/s", value: 1.0 },
                { text: "0.006 - 0.01 m/s", value: 1.5 },
                { text: "0.011 - 0.02 m/s", value: 2.5 },
                { text: "0.021 - 0.05 m/s", value: 4.0 },
                { text: "0.051 - 0.1 m/s", value: 6.0 },
            ],
            b4OptionsSteelBronze: [
                { text: "0.002 - 0.005 m/s", value: 4.2 },
                { text: "0.006 - 0.01 m/s", value: 5.5 },
                { text: "0.011 - 0.02 m/s", value: 6.5 },
                { text: "0.021 - 0.05 m/s", value: 8.0 },
                { text: "0.051 - 0.1 m/s", value: 10.25 },
            ],

            b4RangeSteelSteel: [
                { range: [0.002, 0.005], value: 1.0 },
                { range: [0.006, 0.01], value: 1.5 },
                { range: [0.011, 0.02], value: 2.5 },
                { range: [0.021, 0.05], value: 4.0 },
                { range: [0.051, 0.1], value: 6.0 },
            ],
            b4RangeSteelBronze: [
                { range: [0.002, 0.005], value: 4.2 },
                { range: [0.006, 0.01], value: 5.5 },
                { range: [0.011, 0.02], value: 6.5 },
                { range: [0.021, 0.05], value: 8.0 },
                { range: [0.051, 0.1], value: 10.25 },
            ],
            
            b5OptionsSteelBronze: [
                { text: "5°", value: 1.0 },
                { text: "10°", value: 2.5 },
                { text: "20°", value: 5.0 },
                { text: "30°", value: 7.8 },
                { text: "45°", value: 8.8 },
            ],
            b5OptionsSteel: [
                { text: "5°", value: 3.5 },
                { text: "10°", value: 4.0 },
                { text: "20°", value: 5.6 },
                { text: "30°", value: 8.0 },
                { text: "45°", value: 9.0 },
            ],
            fBetaOptionsSteel: [
                { text: "5°", value: 3.5 },
                { text: "10°", value: 4.3 },
                { text: "15°", value: 5.2 },
                { text: "20°", value: 5.5 },
            ],
            fBetaOptionsSteelBronze: [
                { text: "5°", value: 1.0 },
                { text: "10°", value: 3.5 },
                { text: "15°", value: 5.1 },
                { text: "20°", value: 5.5 },
            ],
            selectedMaterial: "Steel/Steel", // Default material
        };
    },
    watch: {
        // Watch all required fields for H calculation
        b1: "calculateH",
        b2: "calculateH",
        b3: "calculateH",
        b4: "calculateH",
        b5: "calculateH",
        p: "calculateH",
        //v: "calculateH",
        N: "calculateH",
        selectedMaterial() {
            // Reset b4, b5, and fBeta when the material type changes
            this.b4 = null;
            this.b5 = null;
            this.H = null;
            this.fBeta = null;
            this.autoSelectB4(this.v);
        },
        // Watch for changes in v to update b4
        v(newValue) {
            if (newValue >= 0.002 && newValue <= 0.1) {
                this.autoSelectB4(newValue);
            } else {
                this.b4 = null; // Reset if out of range
            }
            this.calculateH();
        },
        // Watch for changes in H to calculate fH
        H(newValue) {
            if (newValue >= 1 && newValue <= 50) {
                if (newValue < 5) {
                    // Use the dotted line for H < 5
                    this.fH = (1 + (newValue - 1) * 0.25).toFixed(2); // Approximate interpolation
                } else if (newValue >= 5 && newValue < 40) {
                    // Use the solid line
                    if (newValue <= 10) this.fH = 2.2;
                    else if (newValue <= 20) this.fH = 4;
                    else if (newValue <= 30) this.fH = 5;
                    else this.fH = 5.5;
                } else {
                    // For H >= 40, fH is constant
                    this.fH = 5.5;
                }
            } else {
                this.fH = null; // Reset if H is out of range
            }
        },
    },
    created(){
        this.autoSelectB4(this.v);
    },
    methods: {
        validateP(value) {
            return value >= 10 || "p must be at least 10";
        },
        // Enforce minimum value for `p`
        enforceMinP() {
            if (this.p < 10) {
                this.p = 10;
            }
        },
        autoSelectB4(newValue){
            const options =
                    this.selectedMaterial === "Steel/Steel"
                        ? this.b4RangeSteelSteel
                        : this.b4RangeSteelBronze;

            const selectedOption = options.find(
                (option) => newValue >= option.range[0] && newValue <= option.range[1]
            );

            if (selectedOption) {
                this.b4 = selectedOption.value;

                //set the label b4OptionsSteelSteel
                const labelOptions =
                    this.selectedMaterial === "Steel/Steel"
                        ? this.b4OptionsSteelSteel
                        : this.b4OptionsSteelBronze;

                const selectedLabelOption = labelOptions.find(
                    (option) => this.b4 == option.value
                );
                if(selectedLabelOption){
                    this.b4Label = selectedLabelOption.text;
                }
            }
        },
        // General range validation
        validateRange(min, max) {
            return (value) =>
                (value >= min && value <= max) ||
                `Value must be between ${min} and ${max}`;
        },
        calculateH() {            
            // Ensure all required fields are filled
            if (this.b1 && this.b2 && this.b3 && this.b4 && this.b5 && this.p && this.v && this.N) {
                console.log(this.b1, this.b2, this.b3, this.v, this.b4, this.b5, this.p,  this.N);
                const Gh =
                    (this.b1 * this.b2 * this.b3 * this.b4 * this.b5 * 330) /
                    (Math.pow(this.p, 2.5) * this.v);
                const H = Gh / this.N;
                this.H = H.toFixed(2);
                // Validate H range and set it
                //this.H = H >= 1 && H <= 50 ? H.toFixed(2) : null;
                console.log('H: ', this.H);
            } else {
                console.log('else part');
                this.H = null;
            }
        },
        calculate() {
            if (this.$refs.form.validate()){
                const Gh =
                    ((this.b1 * this.b2 * this.b3 * this.b4 * this.b5 * 330) /
                    (Math.pow(this.p, 2.5) * this.v)).toFixed(2);
            
                const GhN = (Gh * this.fBeta * this.fH).toFixed(2);
                const GN = (60 * this.f * GhN).toFixed(2);
                const H = this.H;

                this.results = { Gh, GhN, GN, H };
            }else{
                this.results = null;
            }
            
        },
        reset() {
            this.b1 = this.b2 = this.b3 = this.b4 = this.b5 = null;
            this.fBeta = this.fH = this.p = this.v = this.f = this.N = null;
            this.H = null;
            this.selectedMaterial = "Steel/Steel"; // Reset to default
            this.results = null;
        },
    },
};
</script>
