<template>
    <div>
      <current-project />
      <!-- <v-row>
        <v-col cols="12" class="pb-0">
          <current-project />
        </v-col>
        <v-col cols="12" class="pt-0 pb-0" >
            <current-project-section2 />
        </v-col>
        <v-col cols="12" class="pt-0 pb-0">
          <current-project-section3 />
        </v-col>
      </v-row> -->        
    </div>
  </template>
  <script>
    import CurrentProject from '../projects/current-projects/current-project';
    
    //import ImageCollage from '../projects/current-projects/image-collage';
    // import CalculatorCom from '../projects/current-projects/calculator-com';
  
    export default{
        components: {
            CurrentProject,
            // CurrentProjectSection2,
            // CurrentProjectSection3
            // CalculatorCom
        },
    }
  </script>