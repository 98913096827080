<template>
    <div>
      <v-row>
        <v-col cols="12" class="pb-0">
          <event-comp />
        </v-col>
        <v-col cols="12" class="pt-0 pb-0" >
            <ride-description />
        </v-col>
      </v-row>
        
    </div>
  </template>
  <script>
    import EventComp from '../events/events-comp';
    import RideDescription from '../events/ride-description';
  
    export default{
        components: {
            EventComp,
            RideDescription
        },
    }
  </script>