<template>
  <div>
    <v-row>
      <v-col cols="12" class="pb-0">
        <home-carousel />
      </v-col>
      <v-col cols="12" class="pt-0 pb-0" >
        <bamba-community />
      </v-col>
      <v-col cols="12" class="pt-0 pb-0">
        <stats-section />
      </v-col>
      <v-col cols="12" class="pt-0 pb-0">
        <history-section />
      </v-col>
      <v-col cols="12" class="pt-0 pb-0">
        <subscribe-comp />
      </v-col>
    </v-row>
      
  </div>
</template>
<script>
  import HomeCarousel from '../home/home-carousel';
  import BambaCommunity from '../home/bamba-community';
  import StatsSection from '../home/stats-section';
  import HistorySection from '../home/history-section';
  import SubscribeComp from '../home/subscribe-comp';

  export default{
      components: {
        HomeCarousel,
        BambaCommunity,
        StatsSection,
        HistorySection,
        SubscribeComp
      },
  }
</script>