<template>
    <v-footer
      :style="{ backgroundColor: 'rgba(51,51,51,255)' }"
      class="footer"
      padless
    >
      <v-container fluid>
        <v-row class="d-flex justify-space-between align-center">
          <v-col cols="12" md="6" :class="$vuetify.breakpoint.mdAndUp ? 'text-left' : 'text-center'" class="py-3">
            <span class="white--text footer-text">
              &copy; 2024 Berks Area Mountain Biking Association. All rights reserved.
            </span>
          </v-col>
          <v-col cols="12" md="6" :class="$vuetify.breakpoint.mdAndUp ? 'text-right' : 'text-center'"  class="py-3">
            <v-btn icon class="white--text" href="https://www.facebook.com/BerksMountainBiking/" target="_blank" rel="noopener noreferrer">
              <v-icon>mdi-facebook</v-icon>
            </v-btn>
            <v-btn icon class="white--text" href="https://www.instagram.com/berksareamba/" target="_blank" rel="noopener noreferrer">
              <v-icon>mdi-instagram</v-icon>
            </v-btn>
            <v-btn icon class="white--text" href="https://www.youtube.com/channel/UCNrBmK5nBPWeoykgsP_LPhQ" target="_blank" rel="noopener noreferrer">
              <v-icon>mdi-youtube</v-icon>
            </v-btn>
          </v-col>
          <!-- <v-col cols="12" md="4" class="text-center py-3">
            <span class="white--text footer-text">
                <a href="#" class="white--text" @click.prevent>Privacy Policy</a> |
                <a href="#" class="white--text" @click.prevent>Terms of Service</a>
            </span>
          </v-col> -->
        </v-row>
      </v-container>
    </v-footer>
  </template>
  
  <script>
  export default {
    name: 'footer-comp',
  };
  </script>
  
  <style scoped>
  .footer {
    padding: 0;
    font-size: 14px;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
  }
  
  .footer-text {
    font-weight: 300;
    letter-spacing: 0.5px;
  }
  
  .v-footer {
    font-size: 15px;
  }
  </style>
  