<template>
  <v-container class="history-section" fluid>
    <loader-comp :loading="loading" />
    <v-row align="center" justify="center">
      <v-col cols="12" md="6" class="history-image">
        <v-img src="@/assets/images/home/history-2.jpg" alt="History Image" class="elevation-2" />
      </v-col>
      <v-col cols="12" md="6">
        <v-card class="history-card" elevation="3">
          <v-card-title class="headline font-weight-bold card-title">Subscribe</v-card-title>
          <v-card-subtitle class="content mt-3 mb-2">Sign up to our newsletter and stay up to date!</v-card-subtitle>
          <v-form ref="form" v-model="valid">
            <v-card-text class="content-font">
              <v-text-field v-model="email" required :rules="emailRules" class="content-font"
                label="Enter your e-mail address" outlined dense color="white"></v-text-field>
              <vue-hcaptcha ref="hcaptcha" sitekey="26fad0e6-1068-4bee-9193-3e095e48dd5f" @verify="onCaptchaVerified"
                @expired="onCaptchaExpired" />
              <v-snackbar v-model="snackbar" :timeout="timeout" class="snackbar-modal" :color="snackbarColor">
                <div class="d-flex justify-space-between align-center">
                  {{ snackbarMessage }}
                  <v-btn color="white" class="ml-2" text @click="snackbar = false">Close</v-btn>
                </div>
              </v-snackbar>
            </v-card-text>
            <v-card-actions>
              <v-btn class="community-button" large @click="onSubmit">Subscribe</v-btn>
            </v-card-actions>
          </v-form>
          <v-card-text class="content-secondary">
            Click here to grant BAMBA permission to email you. We never share emails and you may unsubscribe at any
            time.
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import VueHcaptcha from '@hcaptcha/vue-hcaptcha';
  import axiosInstance from '@/utils/axiosConfig';
  import LoaderComp from '../shared/loader-comp';
  export default {
    name: "HistorySection",
    data() {
      return {
        valid: false,
        loading: false,
        siteKey: "26fad0e6-1068-4bee-9193-3e095e48dd5f",
        email: '',
        emailRules: [
          v => (v && !!v.trim()) || 'Email is required',
          v => (v && /.+@.+\..+/.test(v.trim())) || 'Email must be valid',
        ],
        captchaToken: null,
        captchaLoaded: false,
        snackbar: false,
        snackbarColor: '',
        snackbarMessage: '',
        timeout: 12000,
        subscribedMsg: `Thank you for subscribing to our newsletter. You'll hear from us soon!`,
      }
    },
    components: {
      VueHcaptcha,
      LoaderComp
    },
    methods: {
      onCaptchaVerified(token) {
        this.captchaToken = token;
      },
      onCaptchaExpired() {
        this.captchaToken = null;
      },
      async onSubmit() {
        if (this.$refs.form.validate() && this.captchaToken) {
          try {
            this.loading = true;
            const payloadSubscription = {
              email: this.email.trim(),
              token: this.captchaToken,
              source: 1
            }
            await axiosInstance.post('contact.php', payloadSubscription);
            this.snackbarMessage = this.subscribedMsg;
            this.snackbarColor = 'success';
            this.snackbar = true;
            this.resetForm();
            this.loading = false;
          } catch (error) {
            //console.log('error: ', error);
            this.snackbarMessage = error.response.data.message;
            this.snackbarColor = 'error';
            this.snackbar = true;
            this.loading = false;
          }
        } else {
          this.snackbarMessage = 'Please fill all the required fields.';
          this.snackbarColor = 'error';
          this.snackbar = true;
        }
      },
      resetForm() {
        this.$refs.hcaptcha.reset();
        this.captchaToken = null;
        this.email = '';
        if (this.$refs.form)
          this.$refs.form.reset();
      }
    }
  };
</script>

<style scoped>
  .history-section {
    background-color: rgba(63, 63, 63, 255);
    padding: 40px 20px;
  }

  .history-image {
    display: flex;
    justify-content: center;
  }

  .history-card {
    background-color: #797b7afc;
    padding: 20px;
  }

  .content {
    color: #fff !important;
    font-size: 110.1%;
  }

  .card-title {
    color: #fff !important;
    font-size: 2rem !important;
  }

  /* .history-card .v-btn {
    margin-top: 15px;
  } */
  .community-button {
    color: #fff;
    margin: 10px;
    background-color: #444 !important;
    border-color: #444 !important;
    padding: 8px 16px !important;
    font-weight: 500;
    font-size: 16px;
    text-transform: uppercase;
  }

  .content-font {
    font-size: 16px !important;
  }

  .content-secondary {
    color: #fff !important;
    font-size: 100.1%;
  }

  .snackbar-modal {
    position: absolute;
    bottom: 16px;
    right: 16px;
  }
</style>