<template>
    <v-container class="pa-0" fluid>
        <!-- <v-row>
            <v-col cols="12" class="text-center top-section">
                <div class="fist-section mt-3 mb-8">
                    <h1 class="mt-4">Year in Review</h1>
                    <h4 class="my-3">Click below to read our annual report:</h4>
                    <v-card elevation="2" class="card-fixed hover-effect">
                        <a href="https://storage.googleapis.com/production-constantcontact-v1-0-3/163/654163/6biOFsfd/15145402d1c64625b7a82bfbb953842a?fileName=BAMBA Annual Report 2021-2022.pdf"
                            data-href="https://storage.googleapis.com/production-constantcontact-v1-0-3/163/654163/6biOFsfd/15145402d1c64625b7a82bfbb953842a?fileName=BAMBA Annual Report 2021-2022.pdf"
                            target="_blank">
                            <v-img :src="require(`@/assets/images/completed-projects/completed-projects.jpg`)"
                                alt="completed projects" contain></v-img>
                        </a>

                        <v-card-title class="font-weight-bold justify-center">
                            2021-2022
                        </v-card-title>
                    </v-card>
                </div>
            </v-col>
        </v-row> -->

        <v-row>
            <v-col cols="12" class="mt-2">
                <div class="completed-proj-header">
                    <h1>Previous Completed Projects</h1>
                </div>
            </v-col>
            <!-- Repeat this structure for each year -->
            <v-col cols="12" md="6" class="col1">
                <div class="detail-section text-color">
                    <h1>2020 Goals and Accomplishments</h1>
                    <h4>COMMUNITY OUTREACH</h4>
                    <p class>TNR’s continue adding more riders and ride leaders.</p>
                    <p>Intermediate and advanced rides added</p>
                    <p>New locations added</p>
                    <p>TNR (Tuesdy Night Rides) - Still going strong 4 years later. Thank you JP and ride leaders!</p>

                    <h4>YOUTH CYCLING</h4>
                    <p>Participation in EcoCamp</p>

                    <h4>MT. PENN PRESERVE</h4>
                    <p>Finished installing trail signage on Mt. Penn trails</p>

                    <h4>BLUE MARSH TRAIL RESTORATION PROJECTS</h4>
                    <p>Managed, coordinated and facilitated funding for all project-related activities</p>
                    <p>Completed Phase 1 and 2</p>
                    <p>Trails added from Stilling Basin parking lots to Visitor’s Center and the Skills Area</p>

                    <h4>TRAIL MAINTENANCE</h4>
                    <p>Many efforts throughout the year as needed at Mt. Penn Preserve, Antietam Lake Park, Neversink
                        Mountain Preserve, Haycreek/Birdsboro Waters, French Creek State Park, Blue Marsh Lake.</p>

                </div>
            </v-col>

            <v-col cols="12" md="6" class="col2">
                <div class="detail-section text-color">
                    <h1 class="mb-3">2019 Goals and Accomplishments</h1>
                    <h4>GROUP RIDES</h4>
                    <p class>TNR (Tuesday Night Rides) - Attracted new riders and ride leaders</p>
                    <p>Intro to MTB Program</p>
                    <p>Adaptive Demo Day - Added more vendors, attract more riders</p>
                    <p>Kid’s Rodeo - Help make the kids events bigger and better than before.</p>
                    <p>Blue Marsh “GO DAY”</p>
                    <p>Take A Kid MTB Day</p>
                    <p>Intro to MTB Series began</p>

                    <h4>IMBA RIDE CENTER RECERTIFICATION</h4>
                    <p>Committee formed to assemble required data, secure funding and prepare application</p>
                    <p>Study feasibility of applying for Silver status, but ultimately maintained Bronze status</p>

                    <h4>MT. PENN PRESERVE</h4>
                    <p>Install 4 more trail kiosks</p>
                    <p>Trail signage project continues</p>
                    <p>Host Demo Days for various local bike shops</p>

                    <h4>NEVERSINK MOUNTAIN PRESERVE</h4>
                    <p>Determine feasibility of trail marker project to propose and install signage, and to promote and
                        improve trail system usability</p>

                    <h4>TRAIL MAINTENANCE</h4>
                    <p>Efforts throughout the year as needed at Mt. Penn Preserve, Antietam Lake Park, Neversink
                        Mountain Preserve, Haycreek/Birdsboro Waters, French Creek State Park, Blue Marsh Lake. </p>

                    <h4>BLUE MARSH TRAIL RESTORATION PROJECTS</h4>
                    <p>Funding acquisition, site visits and coordination with Trail Solutions to create the Master Plan
                        for the Blue Marsh trails.</p>
                </div>
            </v-col>
            <v-col cols="12" md="6" class="col2">
                <div class="detail-section text-color">
                    <h1 class="mb-3">2018 Goals and Accomplishments</h1>
                    <a href="https://storage.googleapis.com/production-constantcontact-v1-0-3/163/654163/6biOFsfd/65b69a15817949fd959f4b67e9a7e434?fileName=BAMBA Year in Review 2018 24x36.pdf"
                        class="text-color" target="_blank" rel="noopener noreferrer">
                        <h4>see the year in review flyer here</h4>
                    </a>

                    <h4>COMMUNITY OUTREACH</h4>
                    <p>Conduct more organized rides to introduce new riders to mountain biking. </p>
                    <p>Intro to MTB Program</p>
                    <p>TNR’s (Tuesday night rides) 2 year anniversary</p>
                    <p>Women’s rides</p>
                    <p>Kid Events - conduct more mountain biking activities to include kids.</p>
                    <p>Berks Bike Ramble</p>
                    <p>Pork n Sauerkraut Ride</p>
                    <p>Blue Marsh GO DAY Kids Ride</p>

                    <h4>IMBA RIDE CENTER CERTIFICATION</h4>
                    <p>Continuously focus on improving the overall trail experience in the Greater Reading Area,
                        striving to achieve Silver level status next year.</p>

                    <h4>TRAIL MAINTENANCE</h4>
                    <p>Maintain trails on Mt. Penn, Neversink, S.R.T., S.R.G. trails, Haycreek/Birdsboro Waters, and
                        assist rangers at Blue Marsh and French Creek.</p>
                    <p>Organize and cleanup the SRT from the Bingaman St Bridge to the Laurel St Railroad Bridge and the
                        route up to gate 9 on the Neversink Preserve.</p>
                    <p>Continue the restoration of Egelman Park and Mineral Spring Park trails and grounds.</p>

                    <h4>DEMO DAYS</h4>
                    <p>Assist local bike shops in organizing and conducting bike Demo Day events to implement annually.
                    </p>

                    <h4>TRAILFORKS IMPLEMENTATION</h4>
                    <p>Electronic trail mapping continues at the various trail networks in the greater Reading Area.
                    </p>

                    <h4>MT. PENN PRESERVE</h4>
                    <p>Install four internally located mini trail information kiosks</p>
                    <p>Pump Track proposal to the City of Reading for Egelman Park</p>
                    <p>Community Involvement - Participate in the 422 West Shore Bypass</p>
                    <p>Stakeholders Workgroup</p>

                    <h4>NEVERSINK MOUNTAIN PRESERVE</h4>
                    <p>Develop a Trail Map for Neversink Preserve and propose signage.</p>

                    <h4>FIXIT STATIONS</h4>
                    <p>Three more stations installed:</p>
                    <p class="ml-2">- 9th St. Gate on Neversink Mountain Preserve</p>
                    <p class="ml-2">- Church Rd. at Blue Marsh Lake</p>
                    <p class="ml-2">- Gring's Mill/SRT</p>
                </div>
            </v-col>
            <v-col cols="12" md="6" class="col1">
                <div class="detail-section text-color">
                    <h1 class="mb-3">2017 Goals and Accomplishments</h1>
                    <a href="https://storage.googleapis.com/production-constantcontact-v1-0-3/163/654163/6biOFsfd/deff157172014eb997225d5a0278e262?fileName=BAMBA Year in Review 2017 24x36.pdf"
                        class="text-color" target="_blank" rel="noopener noreferrer">
                        <h4>see the year in review flyer here</h4>
                    </a>

                    <h4>ORGANIZATIONAL CHANGE</h4>
                    <p>BAMBA becomes an independent 501(c)3, and is now an IMBA Co-branded Affiliate.</p>

                    <h4>COMMUNITY OUTREACH</h4>
                    <p>TNR's (Tuesday Night Rides) - Ride series 1 year old! Thanks to JP for keeping it going.</p>
                    <p>Kids Bike Rodeo - giveaways for the kids provided by East Ridge Outfitters, JB's Bike Shop,
                        Shirk's Bike Shop and Wolverton's Bike Shop. It was fun for all!
                        Pop Up Picnic - held in August. Fun, food, friends!</p>

                    <h4>TRAILFORKS IMPLEMENTATION</h4>
                    <p>A large effort by several contributors is underway to electronically map the trails throughout
                        the various trail networks in the greater Reading Area.</p>

                    <h4>NEVERSINK MOUNTIAN PRESERVE</h4>
                    <p>Replaced the three Klapperthal trail bridges from the old 32 inch width to 5 ft. wide in order to
                        accommodate adaptive cyclists.</p>
                    <p>Improvements made to Klapperthal parking lot; the concrete stop blocks at the Klapperthal lot now
                        greet visitors with an original Mike Miller mural.</p>
                    <p>Transformed the 9th Street Gate 9 trail head into a prominent, welcoming, safe gateway to the
                        Neversink west trail. McElvain Pavilion - Cut back the vegetation and removed trash from the
                        area around the pavilion and the trail head at Gate 2 (Neversink Mountain Road).</p>

                    <h4>MT. PENN PRESERVE</h4>
                    <p>“Chris Crossing” - Installed bridge over the wetland on the trail just above the Oak Lane
                        trailhead.</p>
                    <p>Fire Tower Parking Lot Gate - Replaced broken gate at the south end of the fire tower parking lot
                    </p>
                    <p>Sphuler Road Trail Kiosk - Fabricated and installed a kiosk at the intersection of the Tower
                        Trail and Sphuler Rd.</p>

                    <h4>PENNSYLVANIA GREENWAYS AND TRAIL SUMMIT</h4>
                    <p>BAMBA conducted a mobile workshop which gave participants a tour of Mt. Penn and a trail ride.
                    </p>
                    <p>Park administrators from across the state experienced the Reading Area's iconic features (The
                        Pagoda, Skyline Drive, Fire Tower, Antietam Lake) and got to ride our IMBA Bronze Level Ride
                        Center trails.</p>

                    <h4>FIXIT STATIONS</h4>
                    <p>Fixit Stations - Installed a bike repair station at the BAMBA parking lot on List Road. - Two
                        more stations are to be installed next year - one at Antietam Lake Park and one at Blue Marsh.
                    </p>
                </div>
            </v-col>
            <v-col cols="12" md="6" class="col1">
                <div class="detail-section text-color">
                    <h1 class="mb-3">2016 Goals and Accomplishments</h1>

                    <h4>MT. PENN PRESERVE</h4>
                    <p>Published a Mt Penn bike trail map</p>
                    <p>Began to install trail signage</p>
                    <p>Install a trail information kiosk at the BAMBA parking lot and at Egelman Park</p>
                    <p>Install a trailhead sign at the BAMBA parking lot</p>

                    <h4>SCHUYLKILL RIVER TRAIL</h4>
                    <p>Craig’s Link - Improve the connector trail and install lighting from Old Wyomissing Rd to the
                        RACC Bridge</p>

                    <h4>NEVERSINK MOUNTIAN PRESERVE</h4>
                    <p>Project begins to rebuild the Klapperthal Hollow bridges to accommodate hand cycles and
                        wheelchairs</p>

                    <h4>GROUP RIDES</h4>
                    <p>Tuesday Night Rides</p>
                    <p>Start Sunday Women’s Group Rides</p>

                    <h4>IMBA BRONZE LEVEL RIDE CENTER AWARD</h4>
                    <p>Reading, PA recognized as an IMBA Bronze Level Ride Center</p>

                    <h4>GO GREATER READING PARTNERSHIP</h4>
                    <p>A Mountain biker made the cover of the 2016 official Travel Guide</p>

                    <h4>NEVERSINK MOUNTAIN PRESERVE</h4>
                    <p>Dirt Rag Mountain Bike Magazine, Issue 189 features article on riding at Neversink Mountain
                        Preserve.</p>

                </div>
            </v-col>
            <v-col cols="12" md="6" class="col2">
                <div class="detail-section text-color">
                    <h1 class="mb-3">2015 Goals and Accomplishments</h1>

                    <h4>MT. PENN PRESERVE</h4>
                    <p>A-Line Gravity Trail designed and built</p>
                    <p>BAMBA Parking Lot built as a new parking lot on List Rd. next to Camp Lilly to provide quick and
                        easy access to some of Mt. Penn’s best trails.</p>
                    <p>Seven new fire gates installed</p>
                    <p>Signage project begins; over 30 sign posts installed on the trails near A-Line</p>
                    <p>Procure funding for more bike trail maps, kiosks, trail information and signage next year</p>
                    <p>Pocket Bike Trail Maps created - Many volunteer hours spent studying maps, riding and verifying
                        trails, drawing maps.</p>

                    <h4>NATIONAL DESIGNATION AWARDED TO CITY OF READING</h4>
                    <a href="https://storage.googleapis.com/production-constantcontact-v1-0-3/163/654163/6biOFsfd/ebfbd3bc08634333add8a0e26f756e9c?fileName=BFC_Fall_2015_ReportCard_Reading_PA.pdf"
                        class="text-color" target="_blank" rel="noopener noreferrer">
                        <p>Designation of Bike Friendly Community at the Bronze level by the League of American Cyclists
                        </p>
                    </a>
                    <h5 class="mt-4">News from the Executive Office of the Mayor of Reading:</h5>
                    <p>Reading, PA – November 18, 2015</p>
                    <p>The City of Reading is proud to announce that it has been awarded the national designation of
                        Bike Friendly Community at the Bronze level by the League of American Bicyclists. Reading joins
                        State College, Franklin, York, Pittsburgh, and Philadelphia as the only municipalities in
                        Pennsylvania to achieve official designation by the League for their efforts to become bike
                        friendly. According to the League, “simple steps to make bicycling safe and comfortable pay huge
                        dividends in civic, community and economic development. Given the opportunity to ride, residents
                        enjoy dramatic health benefits, reduced congestion, increased property values and more money in
                        their pockets to spend in the local economy. When your community welcomes bicycling, tourism
                        booms, businesses attract the best and the brightest, and governments save big on parking costs
                        while cutting their carbon emissions.” “This latest achievement was the result of so many
                        partners working together towards a common vision,” explained Eron Lloyd, who submitted the
                        application and serves as the City’s bike and pedestrian coordinator. “As our third attempt in
                        seeking the designation, the recent successes of establishing the Reading Bike Hub, BAMBA's
                        achievement of International Ride Center status for the Greater Reading Trails System, our
                        Complete Streets policy, the Reading 120 race, and Bicycle Friendly Business status for the
                        Reading Eagle and Penske Racing Shocks all helped to push us over the top this time.</p>

                    <h4>SRT RATED #1 URBAN TRAIL IN THE USA</h4>
                    <a href="https://www.10best.com/awards/travel/best-urban-trail/" class="text-color" target="_blank"
                        rel="noopener noreferrer">
                        <p>Schuylkill River Trail rated as the #1 Urban Trail in the USA (2015) </p>
                    </a>
                </div>
            </v-col>
            <v-col cols="12" md="6" class="col2">
                <div class="detail-section text-color">
                    <h1 class="mb-3">2014-2013 Goals and Accomplishments</h1>

                    <h4>MT. PENN PRESERVE & ANTIETAM LAKE PARK</h4>
                    <p>Fire Road Gate Project is underway, installing 10 lockable gates. This project helps protect the
                        trail system from illegal dumping and ensure clear access for emergency services. This project
                        was completed in partnership with many generous local volunteers, the City of Reading, and the
                        local area VoTech.</p>
                </div>
            </v-col>
            <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="12" md="6" class="col1"></v-col>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        name: "YearInReview",
    };
</script>

<style scoped>
    .text-color {
        color: #fff;
    }

    .top-section {
        background-color: #3F3F3F;
        color: #fff;
    }

    .card-fixed {
        background-color: #505050;
        color: #fff;
        min-width: 330px;
        max-width: 330px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        transition: transform 0.3s ease, box-shadow 0.3s ease;
    }

    .card-fixed.hover-effect:hover {
        transform: scale(1.05);
        /* Slightly enlarges the card */
        box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
    }

    h1 {
        font-size: 2rem;
        font-weight: bold;
    }

    h4 {
        font-size: 1.2rem;
        font-weight: bold;
    }

    h5 {
        font-size: 1.1rem;
        font-weight: bold;
    }

    h3 {
        font-size: 1.5rem;
        font-weight: bold;
    }

    .fist-section {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .col1 {
        background-color: #333333;
        padding: 20px;
    }

    .col2 {
        background-color: #3D3D3D;
        padding: 20px;
    }

    .detail-section {
        padding: 30px;
    }

    .detail-section p {
        margin-bottom: 2px !important;
    }

    .detail-section h4 {
        margin-top: 12px !important
    }
    .completed-proj-header{
        text-align: center;
    }
</style>