<template>
    <div>
      <completed-projects />
    </div>
  </template>
  
  <script>
   import CompletedProjects from '../../components/pages/completed-projects-page';
  
    export default {
      name: 'completed-projects-view',
      metaInfo: {
        title: 'Completed Projects - Berks Area Mountain Biking Association',
      },
      components: {
        CompletedProjects
      },
    }
  </script>
  