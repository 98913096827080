<template>
    <v-container fluid>
      <v-row justify="center" class="custom-background text-color">
        <v-col cols="12" md="10" lg="7">
          <h2 class="mb-4">Repaving Highland Road at Blue Marsh to the Holl’s Lot</h2>
      <p class="subheader">
        With the help of Mike Hohl from Lyons & Hohl paving, we were able to repave Highland Road from Brownsville Road to the Holl's trailhead parking lot.
      </p>
      
  
      <!-- Image Collage -->
      <div class="image-collage" ref="imageCollage">
        <!-- Large Image -->
        <div class="large-image">
          <img :src="images[0]" alt="Image 1" class="collage-image large" />
        </div>
        <!-- Small Images -->
        <div class="small-images">
          <img v-for="(image, index) in images.slice(1)" :key="index" :src="image" :alt="'Image ' + (index + 2)"
            class="collage-image small" />
        </div>
      </div>
        </v-col>
      </v-row>
      
    </v-container>
  </template>
  
  <script>
    import Viewer from "viewerjs";
    import "viewerjs/dist/viewer.css";
  
    export default {
      data() {
        return {
          images: [
            require("@/assets/images/completed-projects/repaving/cp-1.jpg"),
            require("@/assets/images/completed-projects/repaving/cp-2.jpg"),
            // require("@/assets/images/current-projects/cp-3.jpg"),
            // require("@/assets/images/current-projects/cp-4.jpg"),
            // require("@/assets/images/current-projects/cp-5.jpg"),
          ],
        };
      },
      mounted() {
      // Initialize Viewer.js for lightbox functionality
      this.viewer = new Viewer(this.$refs.imageCollage, {
        toolbar: {
          zoomIn: 1,
          zoomOut: 1,
          oneToOne: 1,
          reset: 1,
          prev: 1,
          play: false,
          next: 1,
          rotateLeft: 1,
          rotateRight: 1,
          flipHorizontal: 1,
          flipVertical: 1,
          download: 1, // Add download option
        },
        title: true,
        navbar: false,
        scalable: true,
        fullscreen: true,
        button: true,
        viewed: () => {
          this.addCustomShareButton(); // Add the share button once Viewer.js is ready
        },
      });
    },
      methods: {
        addCustomShareButton() {
        const shareButton = document.createElement("button");
        shareButton.innerHTML = `<svg aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" class="icon"><path fill="currentColor" d="M561.938 231.219l-117.24-104.34C427.94 116.159 416 121.16 416 132.8v75.759C277.6 212.679 140.48 222.8 48 312c19.2-20.8 67.2-50.4 116-65.6V176C164 164.64 152 159.638 144 168.34l-117.24 104.34c-15.2 13.2-15.2 34.8 0 48l117.24 104.34c8 8.702 20 3.702 24-9.76v-70.04c28-8.801 59.2-12.801 92-12.801 136 0 224 96.801 224 224h16V176C520 131.2 571.94 185.618 561.938 231.219z"></path></svg>`;
        shareButton.title = "Share";
        shareButton.className = "viewer-share-button";
        shareButton.onclick = this.handleShare;
  
        const toolbar = this.viewer.viewer.querySelector(".viewer-toolbar");
        if (toolbar && !toolbar.querySelector(".viewer-share-button")) {
          toolbar.appendChild(shareButton);
        }
      },
      handleShare() {
        const currentImage = this.viewer.image.src;
        alert(`Share functionality triggered for ${currentImage}`);
        // Implement your custom share functionality here
      },
    },
    beforeDestroy() {
      // Destroy Viewer.js instance
      if (this.viewer) {
        this.viewer.destroy();
      }
    },
    };
  </script>
  
  <style scoped>
    .custom-background {
      background-color: #333333;
      padding: 20px;
      color:#fff !important;
    }
    .text-color{
      color:#fff !important;
    }
    .subheader{
      font-size: 14px;
    }
    .link-fw{
      font-weight: 600;
    }
    /* Collage Layout for Desktop/Mid Screens */
    .image-collage {
      display: grid;
      grid-template-columns: 3fr 2fr;
      /* Large image gets more space */
      gap: 10px;
    }
  
    .large-image {
      grid-column: 1;
      grid-row: span 2;
      /* Large image spans two rows */
    }
  
    .large {
      max-height: 320px !important;
      min-height: 320px !important;
      background-size: cover;
    }
  
    .small-images {
      display: grid;
      grid-template-columns: 1fr 1fr;
      /* Small images in 2x2 grid */
      gap: 10px;
    }
  
    .small {
      max-height: 155px !important;
      min-height: 155px !important;
      background-size: cover;
    }
  
    .collage-image {
      width: 100%;
      height: auto;
      max-height: 600px;
      /* Ensures images don’t exceed layout bounds */
      border-radius: 5px;
      cursor: pointer;
      transition: transform 0.3s ease;
    }
  
    .collage-image:hover {
      transform: scale(1.05);
    }
    .viewer-share-button {
    padding: 10px;
    background: transparent;
    color: #fff;
    border: none;
    cursor: pointer;
  }
  
  .viewer-share-button:hover {
    color: #ffcc00;
  }
    /* Layout for Small Screens */
    @media (max-width: 768px) {
      .image-collage {
        grid-template-columns: 1fr;
        /* Single column for all images */
        grid-template-rows: auto auto;
        /* Two rows for layout */
      }
  
      .large-image {
        grid-row: 1;
        /* Large image occupies the first row */
      }
  
      .small-images {
        display: grid;
        grid-template-columns: 1fr 1fr;
        /* Small images occupy the second row in 2x2 grid */
      }
    }
  </style>