<template>
    <v-container class="main-section" fluid>
      <v-row class="justify-center">
        <!-- Title and Description -->
        <v-col cols="12" md="10" class="text-center">
          <h1 class="title-text text-color">VOLUNTEER COMMITTEE MEMBER <br>ROLES AND RESPONSIBILITIES</h1>
          <h3 class="title-sub text-color mt-2 mb-2">Committee Members will be:</h3>
          <p class="heading-text">
            Dedicated to the vision of BAMBA<br>Active in the mountain bike community and members of BAMBA<br>Willing to dedicate time, ensure vision advancement and serve as an ambassador within the community to deepen BAMBA's relationships with land managers, financial and industry partners, members, and supporters.<br>Willing to dedicate 4-8 hours a month on average.<br>Attend monthly committee meetings (many of which can be attended virtually).
          </p>
        </v-col>
        
        <!-- Committees Grid -->
        <v-col cols="12">
          <v-row class="justify-center" dense>
            <committee-card title="Events Committee" 
                           lead="Christa Bender" 
                           members="Jeff Harrison, John Pacharis">
              <div class="about">
                Organizes, identifies, and implements community events involving mountain biking and trail advocacy. Members work with community organizers and other regional organizations to identify opportunities, organize, and recruit volunteers from our membership base. Examples are: Take a Kid Mountain Biking Day, Blood Drives, Mt. Penn Clean Up Days, and BAMBA’s Annual Bike Ramble, holiday social, membership meetings, etc.
              </div>
            </committee-card>
  
            <committee-card title="Trail Development and Maintenance Committee" 
                           lead="Jeff Harrison" 
                           members="John Pacharis, Todd Good, Jude Matteo, Steve VanWinkle, Tom Durofchalk">
              <div class="about">
                Helps to plan and implement new trail projects and regular maintenance activities. Organizes trail crew leaders, or is a trail crew leader, and leads maintenance projects. Members on the Trail Committee will also attend IMBA training. Implementation of regular meetings with local trail managers and land owners to maintain a working relationship and provide support where needed. Track volunteer hours for work and dig days.
              </div>
            </committee-card>

            <committee-card title="Membership and Marketing Committee" 
                           lead="Bob McClennan" 
                           members="Jen Motze, Mark Kelly, Jude Matteo">
              <div class="about">
                Ensures our existing members are satisfied and focuses on growing our membership base.  Experience in marketing, social media, developing and implementing surveys, data analysis and securing sponsorships is beneficial.
              </div>
            </committee-card>

            <committee-card title="Technology Committee" 
                           lead="Jude Matteo" 
                           members="Jude Matteo, Carla Quandel">
              <div class="about">
                Ensures BAMBA has the tools necessary to facilitate volunteer work and communicate effectively with our membership base.  Provide support for various Microsoft programs, MS Teams, and basic website development knowledge is beneficial. Support Trail Maintenance Committee with tracking volunteer hours.
              </div>
            </committee-card>
            
            <committee-card title="Funding Strategies Committee" 
                           lead="Mark Kelly" 
                           members="Mark Kelly, Jude Matteo, Fred Moreadith">
              <div class="about">
                Focused on raising revenues which allows BAMBA to achieve our vision through obtaining donors, corporate sponsorships, and grant awards. Experience in sales, developing relationships, making financial requests, and grant writing is beneficial.
              </div>
            </committee-card>

            <committee-card title="Merchandise Committee" 
                           lead="Todd Papich" 
                           members="Bob McClennan">
              <div class="about">
                Maintain merchandise inventory, send merchandise for online purchases, initiate new orders of merchandise, find and form relationships with providers on merchandise.  Experience in graphic design preferred.
              </div>
            </committee-card>

            <committee-card title="Group Rides Committee" 
                           lead="John Pacharis" 
                           members="Todd Papich">
              <div class="about">
                Schedule Tuesday Night Rides, coordinate ride leads and sweeps, recruit additional ride leads as necessary, plan and coordinate other rides as well as monthly weekend rides and destination rides.
              </div>
            </committee-card>

            <committee-card title="Social Media Committee" 
                           lead="Jude Matteo" 
                           members="John Pacharis and Christa Bender">
              <div class="about">
                Provide weekly updates on BAMBA happenings through emails, Social Media (Facebook and Instagram). Provide updates from special events (i.e. holiday social, TNR, etc.)
              </div>
            </committee-card>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </template>
  
  <script>
    import CommitteeCard from './committee-card.vue';
  export default {
    components: {
      CommitteeCard,
    }
  }
  </script>
  
  <style scoped>
    .main-section{
      background-color: #333333;
      padding: 40px 20px;
    }
    .text-color{
      color:#FFFFFF !important
    }
    .title-text{
      font-weight: bold;
      font-size: 2rem;  
    }
    .title-sub{
      font-size: 120.1%;
    }
  .heading-text {
    font-size: 105.1%;
    color: #FFFFFF;
    margin-bottom: 2rem;
  }
  
  .committee-card {
    background-color: #242424 !important;
    color: #FFFFFF;
    padding: 1rem;
  }
  
  .about {
    font-size: 1rem;
    color: #FFFFFF !important;
  }
  
  v-row, v-col {
    margin-top: 1.5rem;
  }
  </style>
  