<template>
    <v-container fluid class="donation-section">
        <v-row class="justify-center my-10">
            <v-col cols="12" md="9" lg="7" class="px-5">
                <v-row class="justify-center">
                    <v-col cols="12" class="text-center">
                        <h1 class="mb-5">Want to just make a donation?</h1>
                    </v-col>
                    <v-row class="justify-center">
                        <!-- PayPal Card -->
                        <v-col cols="12" sm="6" class="card-holder">
                            <v-card class="pa-4 donation-card">
                                <div class="icon-wrapper">
                                    <div class="icon-circle">
                                        <img src="https://upload.wikimedia.org/wikipedia/commons/b/b5/PayPal.svg"
                                            alt="PayPal" class="icon-svg" />
                                    </div>
                                </div>
                                <v-btn class="mt-3" color="white"
                                    href="https://www.paypal.com/donate/?hosted_button_id=HG96Z7ZEJ359U"
                                    target="_blank">
                                    click here to donate
                                </v-btn>
                            </v-card>
                        </v-col>

                        <!-- Mail Check Card -->
                        <v-col cols="12" sm="6" class="card-holder">
                            <v-card class="pa-4 donation-card">
                                <div class="icon-wrapper">
                                    <div class="icon-circle">
                                        <v-icon large color="black">mdi-email</v-icon>
                                    </div>
                                </div>
                                <div class="mt-3">
                                    <p class="mb-1 font-weight-bold text-decoration-underline">mail check to:</p>
                                    <p class="mb-1">Berks Area Mountain Biking Association</p>
                                    <p class="mb-1">PO Box 6121</p>
                                    <p class="mb-1">Wyomissing, PA 19610</p>
                                </div>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-row>
            </v-col>

        </v-row>
    </v-container>
</template>

<script>
    export default {
        name: "DonationSection",
    };
</script>

<style scoped>
    /* Main Section */
    .donation-section {
        background-color: #3F3F3F;
        color: #fff;
    }
    .card-holder{
        display:flex;
        flex-direction: row;
        justify-content: center;
    }
    /* Cards */
    .donation-card {
        background-color: #555;
        /* Card background */
        color: #fff;
        border-radius: 12px;
        text-align: center;
        box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.5);
        min-height: 270px;
        max-width: 290px;
        min-width: 290px;
    }

    /* Icon Wrapper */
    .icon-wrapper {
        display: flex;
        justify-content: center;
        /* Center the circle horizontally */
        margin-bottom: 16px;
        /* Spacing between the circle and text */
    }

    /* Icon Circle */
    .icon-circle {
        background-color: white;
        /* White circular background */
        border-radius: 50%;
        /* Fully rounded */
        width: 80px;
        /* Fixed circle width */
        height: 80px;
        /* Fixed circle height */
        display: flex;
        align-items: center;
        /* Vertically center the icon */
        justify-content: center;
        /* Horizontally center the icon */
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
        /* Subtle shadow for visual pop */
    }

    /* SVG Icon */
    .icon-svg {
        width: 60px;
        /* Adjust size as needed */
        height: auto;
    }

    /* Header */
    h2 {
        font-weight: bold;
    }

    /* Button */
    v-btn {
        font-weight: bold;
        text-transform: uppercase;
        color: white;
    }
</style>