<template>
  <v-container class="main_v_container" fluid>
    <v-row justify="center">
      <v-col cols="12" md="9">
        <v-row>
          <!-- Header -->
          <v-col cols="12" class="header_container">
            <h1 class="mb-2">News & Announcements</h1>
            <a href="https://lp.constantcontactpages.com/su/8fWMqzW/signup"
              class="text-decoration-underline white_color" target="_blank" rel="noopener noreferrer">Sign up for our
              newsletter here</a>
          </v-col>
          <!-- Responsive Tabs -->
          <v-col cols="12">
            <v-tabs v-model="selectedTab" show-arrows grow class="elevation-0 v-tabs-responsive">
              <v-tab v-for="category in categories" :key="category.category_id"
                @click="fetchPostByCategory(category.category_id)">
                {{ category.category_name }}
              </v-tab>
            </v-tabs>
          </v-col>
          <v-col cols="12">
            <v-row justify="center">
              <v-col cols="12" sm="6" md="6" lg="4" class="d-flex justify-center" v-for="post in paginatedPosts"
                :key="post.id">
                <v-card class="card-fixed hover-effect d-flex flex-column justify-space-between">
                  <!-- Image -->
                  <v-img :src="post.leader_image" aspect-ratio="1.5"></v-img>

                  <!-- Card Text -->
                  <v-card-text class="flex-grow-1 white_color">
                    <p class="text-uppercase text-grey mb-1 text-center">{{ formatDate(post.published_on) }}</p>
                    <h2 class="mb-3">{{ decodeHtml(post.title) }}</h2>
                    <p class="leader_color">{{ decodeHtml(post.leader) }}</p>
                  </v-card-text>

                  <!-- Actions: Positioned at the bottom -->
                  <v-card-actions class="mt-auto">
                    <v-spacer></v-spacer>
                    <v-btn text color="white" @click="showDetail(post.id)">Read More</v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12">
            <v-pagination v-model="page" :length="totalPages" total-visible="7" class="mt-4" circle></v-pagination>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <post-detail :id="postId" :openDialog="showDetailDialog" @close="closeBtn" />
  </v-container>
</template>

<script>
  import PostDetail from './post-detail';
  export default {
    data() {
      return {
        posts: [], // Data fetched from API
        page: 1, // Current page
        selectedTab: 0, // Currently selected tab
        categories: [], // Extracted categories
        itemsPerPage: 3, // Maximum cards per page
        isMdAndAbove: false,
        postId: -99,
        showDetailDialog: false,
      };
    },
    components: {
      PostDetail,
    },
    watch: {
      // Watch for screen size changes
      '$vuetify.breakpoint.name'() {
        this.checkScreenSize();
      },
    },
    computed: {
      filteredPosts() {
        if (this.selectedTab === 0) {
          return this.posts; // 'All' tab
        }

        const selectedCategory = this.categories[this.selectedTab].category_name;
        return this.posts.filter(
          (post) =>
            post.categories &&
            post.categories.some(
              (category) => category.category_name === selectedCategory
            )
        );
      },
      paginatedPosts() {
        const startIndex = (this.page - 1) * this.itemsPerPage;
        const endIndex = startIndex + this.itemsPerPage;
        return this.filteredPosts.slice(startIndex, endIndex);
      },
      totalPages() {
        return Math.ceil(this.filteredPosts.length / this.itemsPerPage);
      },
    },
    methods: {
      closeBtn() {
        this.postId = -99;
        this.showDetailDialog = false;
      },
      decodeHtml(html) {
        const textArea = document.createElement('textarea');
        textArea.innerHTML = html;
        return textArea.value;
      },
      showDetail(id) {
        this.postId = parseInt(id);
        this.showDetailDialog = true;
      },
      checkScreenSize() {
        this.isMdAndAbove = this.$vuetify.breakpoint.mdAndUp;
      },
      formatDate(dateString) {
        const date = new Date(dateString);
        return date.toLocaleDateString(undefined, {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        });
      },
      fetchPostByCategory() {
        this.page = 1;
      },
      async fetchPosts() {
        try {
          const response = await fetch(
            'https://blog-viewer-api.mywebsitebuilder.com/v1.0/664bbe9bd1a149e588acb03840ce89d3/blog/posts?status=published?offset=0&size=9'
          );
          const data = await response.json();
          this.posts = data;

          if (this.posts !== null) {
            const uniqueCategories = new Set();
            uniqueCategories.add(
              JSON.stringify({ category_id: -9999, category_name: 'All' })
            );
            this.posts.forEach((post) => {
              if (post !== null && post.categories) {
                post.categories.forEach((category) => {
                  uniqueCategories.add(JSON.stringify(category));
                });
              }
            });
            this.categories = Array.from(uniqueCategories).map((item) =>
              JSON.parse(item)
            );
          }
        } catch (error) {
          console.error('Error fetching posts:', error);
        }
      },
    },
    mounted() {
      this.fetchPosts(); // Fetch posts on component mount
      this.checkScreenSize(); // Initialize screen size
    },
  };
</script>

<style scoped>
  .main_v_container {
    background-color: #424242;
    color: white;
    padding-top: 30px;
    padding-bottom: 40px;
  }

  .mainContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .header_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .white_color {
    color: white !important;
  }

  .leader_color {
    color: rgba(255, 255, 255, 0.75)
  }

  .card_container {
    display: flex;
    justify-content: center;
  }

  /* Card Styling */
  .card-fixed {
    background-color: #505050;

    min-width: 320px;
    max-width: 320px;
    min-height: 450px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }

  .card-fixed.hover-effect:hover {
    transform: scale(1.05);
    /* Slightly enlarges the card */
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
    /* Adds a shadow for emphasis */
  }
</style>
<style>
  /* Tabs Responsiveness */
  .v-tabs-responsive {
    overflow-x: auto;
    white-space: nowrap;
    display: flex;
  }

  .v-tabs__wrapper {
    display: flex;
  }

  /* Ensure arrows are visible */
  .v-tabs__wrapper--show-arrows {
    position: relative;
  }

  .v-tabs__arrow {
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
  }
</style>