<template>
  <v-container class="main-section" fluid>
    <v-row class="justify-center">
      <!-- Title and Description -->
      <v-col cols="12" md="8" class="text-center">
        <h1 class="title-text text-color">Ride Descriptions</h1>
        <p class="heading-text">
          Ride distance and route can vary depending on many factors such as ride host preference, rider experience or
          weather and trail conditions. Please <a class="text-color" href="mailto:info@berksmountainbiking.org?subject=ride inquiry">email
            us</a> with any specific questions. Both men and women are <span class="attention">always</span>
          welcome on every ride and encouraged to attend!
        </p>
      </v-col>

      <!-- Ride Description Grid -->
      <v-col cols="12" md="10">
        <v-row class="justify-center" dense>
          <description-card title="TNR All Abilities">
            <div class="about">
              TNR (Tuesday Night Ride) is a social-paced, no-drop and inclusive ride where all are welcome. These
              mountain bike rides are around 4-8 miles long and primarily on green and blue single or doubletrack. These
              rides are meant to encourage riders to stay interested in our sport of mountain biking and to expose
              riders to the beautiful trail systems our area has to offer.
            </div>
          </description-card>

          <description-card title="Intermediate Rides">
            <div class="about">
              Intermediate mountain bike rides will be around 6-8 miles, 5-7 MPH pace and a good mix of green, blue, and
              black trails. These rides are intended to provide more of a challenge. You should be confident with
              transitioning from smooth to rougher, narrower trails with small obstacles, and have the ability to
              maneuver through steep technical climbs and descents.
            </div>
          </description-card>

          <description-card title="Advanced Rides">
            <div class="about">
              Advanced mountain bike rides will be around 8-15 miles, 5-8 MPH pace over a variety of technical trails.
              These rides are typically more enduro-oriented. You should be confident riding all types of trails at a
              fairly good pace.
            </div>
          </description-card>

          <description-card title="Gravel Rides">
            <div class="about">
              Gravel rides are a social-paced, no-drop and inclusive ride where all are welcome. There is a mix of
              asphalt, rail trail, double track and some single track depending on the location. Approximate length is
              generally 15-20 miles and can be done on a gravel or hardtail mountain bike.
            </div>
          </description-card>

          <description-card title="Intro to MTB Series">
            <div class="about">
              Our Intro to MTB rides are held every Spring and consist of 6 progressive rides once per week. They start
              with the easiest terrain and gradually increase to more technical terrain and longer lengths. These are
              great instructional rides for beginners or anyone looking to get back into the sport. Come out and enjoy
              the experience, learn and have fun!
            </div>
          </description-card>

          <description-card title="Mountain Bike Skills Clinics">
            <div class="about">
              Maintain merchandise inventory, send merchandise for online purchases, initiate new orders of merchandise,
              find and form relationships with providers on merchandise. Experience in graphic design preferred.
            </div>
          </description-card>

        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import DescriptionCard from './description-card.vue';
  export default {
    components: {
      DescriptionCard,
    }
  }
</script>

<style scoped>
  .main-section {
    background-color: #333333;
    padding: 40px 20px;
  }

  .text-color {
    color: #FFFFFF !important
  }

  .title-text {
    font-weight: bold;
    font-size: 2rem;
  }

  .title-sub {
    font-size: 120.1%;
  }

  .attention {
    font-weight: bold;
    text-decoration: underline;
  }

  .heading-text {
    font-size: 105.1%;
    color: #FFFFFF;
    margin-bottom: 2rem;
  }

  .description-card {
    background-color: #242424 !important;
    color: #FFFFFF;
    padding: 1rem;
  }

  .about {
    font-size: 1rem;
    color: #FFFFFF !important;
  }

  v-row,
  v-col {
    margin-top: 1.5rem;
  }
</style>