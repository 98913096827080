<template>
  <v-container fluid>
    <v-row justify="center" class="custom-background">
      <v-col cols="12" md="10" lg="7">
        <v-row justify="center">
          <!-- Text Section -->
          <v-col cols="12" md="6">
            <v-card class="pa-5 custom-card" outlined>
              <h5 class="text-uppercase mb-3">Learn More About What We Do</h5>
              <h2 class="font-weight-bold mb-3">Current Projects</h2>
              <p class="description">
                Our projects are accomplished entirely by our community of generous
                volunteers. We welcome all ages and abilities to help out; no
                experience is necessary! Let us know how you would like to
                contribute, and be sure to check out our incentives on our
                <a href="/volunteer" class="text-decoration-underline anchor" target="_blank"
                  rel="noopener noreferrer">Volunteer page</a>.
              </p>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: "current-projects",
  };
</script>

<style scoped>
  .custom-background {
    background-color: #3F3F3F;
    min-height: 100vh;
    /* Use viewport height instead of 100% */
    padding: 80px;

    display: flex;
    /* align-items: center; */
    justify-content: center;
  }

  .custom-card {
    background-color: #505050;
    color: #fff;
    border-radius: 8px;
  }

  .anchor {
    color: #fff;
    text-decoration: none;
  }

  .anchor:hover {
    background-color: #b8b6b6;
  }

  .description {
    font-size: 14px;
  }
</style>