<template>
    <div>
      <v-row>
        <v-col cols="12" class="pb-0">
          <about-volunteer />
        </v-col>
        <v-col cols="12" class="pt-0 pb-0" >
          <get-return />
        </v-col>
        <v-col cols="12" class="pt-0 pb-0">
          <get-involved />
        </v-col>
        <v-col cols="12" class="pt-0 pb-0">
          <volunteer-position />
        </v-col>
      </v-row>
        
    </div>
  </template>
  <script>
    import AboutVolunteer from '../volunteer/about-volunteer';
    import GetReturn from '../volunteer/get-return';
    import GetInvolved from '../volunteer/get-involved';
    import VolunteerPosition from '../volunteer/volunteer-position';
    
  
    export default{
        components: {
          AboutVolunteer,
          GetReturn,
          GetInvolved,
          VolunteerPosition
        },
    }
  </script>