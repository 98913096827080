import axios from 'axios';

//const API_BASE_URL = 'http://20.120.119.215:5000/api/';
//const API_BASE_URL = 'http://localhost:5000/api/';
const API_BASE_URL = '/';
// Create an Axios instance
const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Add a request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    // Check if the request should include the Authorization header
    if (config.includeAuthToken) {
      // Get the token from local storage
      const token = localStorage.getItem('token');
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    }
    // Check if the request has FormData
    if (config.data instanceof FormData) {
        // Let Axios set the correct Content-Type (multipart/form-data)
        delete config.headers['Content-Type'];
      }
    return config;
  },
  (error) => {
    // Handle the error
    return Promise.reject(error);
  }
);

export default axiosInstance;
