<template>
    <div>
      <committee-participation />
    </div>
  </template>
  
  <script>
   import CommitteeParticipation from '../../components/pages/committee-participation-comp';
  
    export default {
      name: 'committee-participation-view',
      metaInfo: {
        title: 'Committee Participation - Berks Area Mountain Biking Association',
      },
      components: {
        CommitteeParticipation
      },
    }
  </script>
  