<template>
    <v-container fluid class="join-section" fill-height>
        <v-row class="py-12">
            <v-col cols="12" class="d-flex align-center justify-center content text-center">
                <div>
                    <h1>Join us at our upcoming events!</h1>
                    <h4 class="mt-9">Click on the button to view the details.</h4>
                    <h3 class="fs mt-3">Don't forget your helmet and bring a light if we are riding late afternoon or evening. Have a
                        question? <a class="content" href="mailto:hello@berksmountainbiking.org?subject=event question">email us
                            here</a></h3>
                    <div class="button-container">
                        <v-btn class="btn-style"
                            @click="openUrl('https://www.facebook.com/BerksMountainBiking/past_hosted_events')">Past
                            Events</v-btn>
                        <v-btn class="btn-style"
                            @click="openUrl('https://www.facebook.com/BerksMountainBiking/upcoming_hosted_events')">Upcoming
                            Events</v-btn>
                    </div>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        name: "AboutWe",
        methods: {
            openUrl(url) {
                window.open(url, "_blank");
            }
        }
    };
</script>

<style scoped>
    .join-section {
        position: relative;
        background-image: url('@/assets/images/who-we-are/about-we-bg.jpg');
        background-position: center;
        background-size: cover;
        padding: 0 10%;
        color: #fff;
        overflow: hidden;
        height: 70vh;
    }

    .join-section::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(51, 51, 51, 0.78);
        /* Black overlay with 78% opacity */
        z-index: 1;
    }

    .content {
        position: relative;
        z-index: 2;
        color: #FFFFFF;
        
    }
    .fs{
        font-size: 108.1%;
        font-weight: 400;
    }

    .button-container {
        margin-top: 30px !important;
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 20px;
        margin: 0 auto;
    }

    .btn-style {
        width: 170px !important
    }
</style>