<template>
    <div>
      <region-page />
    </div>
  </template>
  
  <script>
   import RegionPage from '../../components/pages/explore-our-region-page';
  
    export default {
      name: 'explore-our-region-view',
      metaInfo: {
        title: 'Explore Our Region - Berks Area Mountain Biking Association',
      },
      components: {
        RegionPage
      },
    }
  </script>
  