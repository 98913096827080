<template>
  <v-app>
    <v-container> 
      <loader-comp :loading="loading" />
      <v-card class="mx-auto my-12" max-width="500" >
        <v-card-title class="headline">Contact Us</v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="valid">
            <v-text-field
              v-model="firstName"
              label="First Name"
              required
              :rules="requiredFieldRules"
            ></v-text-field>

            <v-text-field
              v-model="lastName"
              label="Last Name"
              required
              :rules="requiredFieldRules"
            ></v-text-field>

            <v-text-field
              v-model="email"
              label="Email"
              required
              :rules="emailRules"
            ></v-text-field>
            <v-text-field
              v-model="subject"
              label="Subject"
              required
              :rules="requiredFieldRules"
            ></v-text-field>
            <v-textarea
              v-model="description"
              label="Message Description"
              :rules="requiredFieldRules"
              required
              rows="3"
            ></v-textarea>
          </v-form>

          <v-snackbar v-model="snackbarModal" :timeout="timeout" class="snackbar-modal" :color="snackbarColor" @timeout="onCloseSnackbar">
            <div class="d-flex justify-space-between align-center">
              {{ snackbarMessageModal }}
              <v-btn color="white" text @click="onCloseSnackbar">Close</v-btn>
            </div>
          </v-snackbar>
        </v-card-text>

        <v-card-actions class="justify-center">
          <v-btn :disabled="!valid" color="primary" class="mb-3" @click="subscribe">Submit</v-btn>
        </v-card-actions>
      </v-card>
    </v-container>
  </v-app>
  </template>
  
  <script>
  import axiosInstance from '@/utils/axiosConfig';
  import LoaderComp from '../shared/loader-comp';
  
  export default {
    name:'contact-com',
    data() {
      return {
        loading: false,
        firstName: '',
        lastName: '',
        email: '',
        subject: '',
        description: '',
        valid: false,
        requiredFieldRules: [v => (v && !!v.trim()) || 'This field is required'],
        emailRules: [
          v => (v && !!v.trim()) || 'Email is required',
          v => (v && /.+@.+\..+/.test(v.trim())) || 'Email must be valid',
        ],
        snackbar: false,
        snackbarColor: '',
        snackbarModal: false,
        snackbarMessageModal: '',
        timeout: 6000,
      };
    },
    components:{
      LoaderComp
    },
    watch: {
      snackbarModal(val) {
        if (!val) {
          // When the snackbar closes (manual close or timeout)
          this.$router.push('/');
        }
      },
    },
    methods: {
      onCloseSnackbar() {
        this.snackbarModal = false;
      },      
      async subscribe() {
        if (this.$refs.form.validate()) {
          const contactData = {
            firstName: this.firstName.trim(),
            lastName: this.lastName.trim(),
            email: this.email.trim(),
            subject: this.subject.trim(),
            description: this.description,
          };
          this.loading = true;
          try{
            const response = await axiosInstance.post('contact', contactData);
            const data = response.data;
            this.snackbarMessageModal = data.message;
            this.snackbarColor = 'success';
            this.snackbarModal = true;
            this.loading = false;
            this.resetForm();
          } catch(error){
            this.snackbarColor = 'error';
            this.snackbarMessageModal = error.response.data.message;
            this.snackbar = true;
            this.loading = false;
          }
        }
      },      
      resetForm() {
        this.firstName = '';
        this.lastName = '';
        this.email = '';
        this.subject = '';
        this.description = '';
        this.loading = false;
        if(this.$refs.form)
          this.$refs.form.reset();     
      },
    },
  };
  </script>
  
  <style scoped>
  .action {
    cursor: pointer;
  }
  .snackbar-modal {
    position: absolute;
    bottom: 16px;
    right: 16px;
  }
  </style>
  