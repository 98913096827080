import { checkLogin, getToken } from '@/helpers/auth';
// import { format, parse, parseISO } from 'date-fns';
// import { formatInTimeZone, toZonedTime  } from 'date-fns-tz';

export const helperMixin = {
  methods: {
    async checkLogin() {
      const loginStatus = await checkLogin();
      return loginStatus;
    },
    getToken(){
      return getToken();
    },

    formattedUtcDate(utcDateString) {
      // Extract the date and time portion manually
      const datePart = utcDateString.split('T')[0];  // yyyy-MM-dd
      const timePart = utcDateString.split('T')[1].split(':');  // HH:mm:ss

      // Combine the parts we want, excluding seconds
      const formattedDateTime = `${datePart} ${timePart[0]}:${timePart[1]}`;

      return formattedDateTime;
    },
  }
}
