<template>
  <v-container fluid class="volunteer-section">
    <!-- Header Section -->
    <v-row class="justify-center text-center  ml-3 mr-3">
      <v-col cols="12" md="4" >
        <h2 class="header-title">Our events would not be successful without our volunteers!</h2>
        <p class="header-subtitle">Read below for a list of open volunteer positions and how to get involved.</p>
      </v-col>
    </v-row>

    <!-- Qualifications and Responsibilities Section -->
    <v-row class="justify-center mt-6 ml-3 mr-3" no-gutters>
      <v-col cols="12" md="5" class="info-box">
        <h3 class="info-title">Volunteer Qualifications</h3>
        <p class="info-text">A desire to help grow and contribute to the mountain bike community in our region <br/> Current BAMBA Membership in good standing<br/> A positive attitude</p>        
      </v-col>

      <v-col cols="12" md="5" class="info-box">
        <h3 class="info-title">What You'll Do</h3>
        <p class="info-text">Advocate for trail stewardship and responsible, respectful riding</p>
        <p class="info-text">Help make exciting initiatives happen</p>
        <p class="info-text">Give back to the community</p>
        <p class="info-text">Share BAMBA's mission and values</p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'VolunteerSection'
}
</script>

<style scoped>
.volunteer-section {
  background-color: #f5f7fa; /* Light gray background */
  padding: 40px 0;
}

.header-title {
  font-size: 30px;
  font-weight: bold;
  color: #333333;
  margin-bottom: 10px;
}

.header-subtitle {
  font-size: 16px;
  color: #555555;
  margin-bottom: 30px;
}

.info-box {
  background-color: #424242; /* Dark gray box */
  padding: 40px !important;
  border-radius: 8px;
  text-align: center !important;
  margin: 10px; /* Adds a gap between the two boxes */
  color: white;
}

.info-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.info-text {
  font-size: 16px;
  margin: 5px 0;
}
</style>
