<template>
  <v-layout>
    <v-app-bar app :style="{ backgroundColor: 'rgba(51,51,51,255)' }" class="pl-0" height="56">
      <div class="d-flex align-center">
        <router-link to="/" class="brand-logo">
          <v-img
            alt="BAMBA Logo"
            contain
            src="../assets/logo/bamba-logo.jpg"
            width="200px"
            height="200px"
            class="logo-img"
          />
        </router-link>
      </div>
      <v-spacer></v-spacer>
      <div class="menu-items d-none d-md-flex">
        <router-link to="/" class="menu-item">HOME</router-link>
        <router-link to="/events" class="menu-item">Events</router-link>
        <router-link to="/news" class="menu-item">News</router-link>
        <!-- Projects Menu with Submenu and Arrow Icon -->
        <v-hover v-slot:default="{ isHovering }">
          <v-menu :value="isHovering" offset-y open-on-hover :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <div class="menu-item d-flex align-center" v-bind="attrs" v-on="on">
                Projects
                <v-icon small class="ml-1" color="white">mdi-chevron-down</v-icon>
              </div>
            </template>
            <v-list class="submenu-list">
              <v-list-item>
                <router-link to="/current-projects" class="submenu-item">Current Project</router-link>
              </v-list-item>
              <v-list-item>
                <router-link to="/completed-projects" class="submenu-item">Completed Project</router-link>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-hover>

        <!-- Get Involved Menu with Submenu and Arrow Icon -->
        <v-hover v-slot:default="{ isHovering }">
          <v-menu :value="isHovering" offset-y open-on-hover :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <div class="menu-item d-flex align-center" v-bind="attrs" v-on="on">
                Get Involved
                <v-icon small class="ml-1" color="white">mdi-chevron-down</v-icon>
              </div>
            </template>
            <v-list class="submenu-list">
              <v-list-item>
                <router-link to="/membership" class="submenu-item">Membership</router-link>
              </v-list-item>
              <v-list-item>
                <router-link to="/sponsorship" class="submenu-item">Sponsorship</router-link>
              </v-list-item>
              <v-list-item>
                <router-link to="/volunteer" class="submenu-item">Volunteer</router-link>
              </v-list-item>
              <v-list-item>
                <router-link to="/committee-participation" class="submenu-item">Committee Participation</router-link>
              </v-list-item>
              <v-list-item>
                <router-link to="/youth-mountain-biking" class="submenu-item">Youth Mountain Biking</router-link>
              </v-list-item>
              <v-list-item>
                <router-link to="/donate" class="submenu-item">Donate</router-link>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-hover>
        <router-link to="/explore-our-region" class="menu-item">Explore Our Region</router-link>
        <router-link to="/who-we-are" class="menu-item">Who We Are</router-link>
        <!-- <router-link to="/" class="menu-item">Store</router-link> -->
      </div>
      <v-spacer></v-spacer>
      <v-btn icon class="d-md-none" @click="menuDrawer = !menuDrawer">
        <v-icon color="#ffcc00">mdi-menu</v-icon>
      </v-btn>
      <!-- <div class="login-button">
        <v-badge
          :content="cartItemCount"
          color="error"
          overlap
          :value="cartItemCount > 0"
        >
        <v-icon @click="goToCart" large color="white">mdi-cart-variant</v-icon>
      </v-badge>
      </div> -->
      
    </v-app-bar>

    <!-- Mobile drawer for menu -->
    <v-navigation-drawer
      v-model="menuDrawer"
      app
      temporary
      class="mobile-drawer mt-14"
      right
      overlay
      width="270"
      :style="{ backgroundColor: 'rgba(51,51,51,255)' }"
    >
      <v-list>
        <v-list-item>
          <router-link to="/" class="menu-item">HOME</router-link>
        </v-list-item>
        <v-list-item>
          <router-link to="/events" class="menu-item">Events</router-link>
        </v-list-item>
        <v-list-item>
          <router-link to="/news" class="menu-item">News</router-link>
        </v-list-item>
        <!-- Projects Submenu in Drawer -->
        <!-- <v-hover v-slot:default="{ isHovering }"> -->
          <v-list-group
            append-icon="mdi-chevron-down"
            color="white"
            class="submenu-item"
            :value="isHovering"
          >
            <template v-slot:activator>
              <span class="menu-item">Projects</span>
            </template>
            <div class="submenu-list-drawer" > <!-- Show submenu only when hovering -->
              <v-list-item>
                <router-link to="/current-projects" class="submenu-item ml-3">Current Project</router-link>
              </v-list-item>
              <v-list-item>
                <router-link to="/completed-projects" class="submenu-item ml-3">Completed Project</router-link>
              </v-list-item>
            </div>
          </v-list-group>
        
        <!-- Get Involved Submenu in Drawer -->
        <v-list-group append-icon="mdi-chevron-down" color="white" class="submenu-item">
          <template v-slot:activator>
            <span class="menu-item">Get Involved</span>
          </template>
          <div class="submenu-list-drawer">
            <v-list-item>
              <router-link to="/membership" class="submenu-item ml-3">Membership</router-link>
            </v-list-item>
            <v-list-item>
              <router-link to="/sponsorship" class="submenu-item ml-3">Sponsorship</router-link>
            </v-list-item>
            <v-list-item>
              <router-link to="/volunteer" class="submenu-item ml-3">Volunteer</router-link>
            </v-list-item>
            <v-list-item>
              <router-link to="/committee-participation" class="submenu-item ml-3">Committee Participation</router-link>
            </v-list-item>
            <v-list-item>
              <router-link to="/youth-mountain-biking" class="submenu-item ml-3">Youth Mountain Biking</router-link>
            </v-list-item>
            <v-list-item>
              <router-link to="/donate" class="submenu-item ml-3">Donate</router-link>
            </v-list-item>
          </div>
        </v-list-group>
        <v-list-item>
          <router-link to="/explore-our-region" class="menu-item">Explore Our Region</router-link>
        </v-list-item>
        <v-list-item>
          <router-link to="/who-we-are" class="menu-item">Who We Are</router-link>
        </v-list-item>
        <!-- <v-list-item>
          <router-link to="/" class="menu-item">Store</router-link>
        </v-list-item> -->
      </v-list>
    </v-navigation-drawer>
  </v-layout>
</template>

<script>

export default {
  components: {
    
  },
  data() {
    return {
      closeOnContentClick: false,
      isHovering: false,
      drawer: false,
      menuDrawer: false, // Mobile menu drawer
      drawerPosition: {},
      hoverProjects: false,
    };
  },
  computed:{
    cartItemCount(){
      return 2;
      //return this.$store.getters.totalCartItem;
    }
  },
  methods: {
    goToCart() {
      this.$router.push('/cart/cart-view');
    }
  }
};
</script>

<style scoped>
.submenu-item .v-list-group__header .v-icon {
  margin-left: -5px; /* Adjust to reduce space */
}
.logo-img {
  max-width: 135px;
  max-height: 200px;
  margin-left: 20px;
}
.brand-logo {
  display: flex;
  align-items: center;
}

.menu-items {
  display: flex;
  gap: 20px;
}


/* Main menu and submenu styling */
.menu-item {
  color: white;
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;
}
.menu-item:hover {
  color: #ffcc00;
}
.submenu-list {
  text-transform: uppercase;
  background-color: rgba(51, 51, 51, 0.9);
}
.submenu-list-drawer {
  text-transform: uppercase;
  background-color: rgba(105, 99, 99, 0.5);
}
.submenu-item {
  color: white;
  text-decoration: none;
}
.submenu-item:hover {
  color: #ffcc00;
}
.login-button {
  display: flex;
  align-items: center;
}
.v-badge__badge {
  top: 0px;
  right: 0px;
}

/* .v-icon {
  font-size: 32px;
} */

.avatar {
  background-color: #ffcc00;
  color: black !important;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px !important;
  min-width: 40px !important;
  width: 40px !important;
}

.cursor-pointer {
  cursor: pointer;
}

.drawer {
  min-height: 120px !important;
  max-height: 120px !important;
  transition: none !important;
  border-radius: 4%;
  transform-origin: top right !important;
}

.mobile-drawer {
  z-index: 1000; /* Ensures it's on top of everything */
}

@media (max-width: 960px) {
  .menu-items {
    display: none; /* Hide menu items in mobile view */
  }
}

@media (max-width: 600px) {
  .menu-items {
    flex-direction: column;
    gap: 10px;
  }
}
</style>

<style>
  .v-toolbar__content {
    padding-left: 0px !important;
  }
  .v-list-group__header__append-icon{
    margin-left: 0px !important;
    padding-left: 0px !important;
  }
  .mdi-chevron-down::before {
      color: white !important;
  }
</style>
